import {Component} from 'react';
import './style.css';

export default class Convite extends Component {
    render() {
        return <div className='hs-con-container'>
            <div className='hs-con-card'>
                <h2 className='hs-con-card__titulo'>De o primeiro passo</h2>

                <h3 className='hs-con-card__subtitulo'>Instale ou conserte seu produto com o servico mais rapido do Brasil</h3>

                <button className='hs-con-card__botao'>Peca ja</button>
            </div>
        </div>
    }
}