import { Component } from 'react';
import './style.css';

export default class EscolhaProduto extends Component {
    render() {
        return <div className='sis-dr-ep-container'>
            <h2 className="sis-dr-ep-titulo">Escolha o produto</h2>

            <div className='sis-dr-ep-wrapper'>
                {this.props.listaProduto.map(item =>
                    <button
                        onClick={() => this.props.setState({ produto: item })}
                        className={this.props.produto === item ?
                            'sis-dr-ep-wrapper__botao sis-dr-ep-wrapper__selecionado' :
                            'sis-dr-ep-wrapper__botao'
                        }
                    >
                        <img
                            src={this.props.urlBaseArquivo + item.categoriaImagem}
                            alt={item.categoriaNome}
                            className='sis-dr-ep-wrapper__imagem'
                        />
                    </button>)}
            </div>
        </div>
    }
}