export default class Constante {
    // url_base = 'http://192.168.1.14:8080/api/';
    url_base = 'https://app.iexbrasil.com.br/api/'
    token = '123';

    Telas = {
        Hotsite: 'sis-hs',
        DigitacaoRapida: 'sis-dr',
        Documento: 'sis-docs',
        // Pessoa: 'sis-pes',
        Financeiro: 'sis-fi',
        Execucao: 'sis-exe',
        MapaDocumento: 'sis-mpdocs',
        Cadastro: 'sis-cad',
        Iexcombo: 'sis-iex'
    }

    /* USUARIO */
    seqUsuario = 69;
    seqUsuarioVendedor = 69;

    /* PERFIL PERFIL */
    seqPessoaPerfilCliente = 8;
    seqPessoaPerfilTecnico = 9;
    seqPessoaPerfiFornecedor = 10;
    seqPessoaPerfilFabricante = 11;
    seqPessoaPerfilTecnicoInteressado = 12;
    seqPessoaPerfilOperacional = 13;
    seqPessoaPerfilEmpresaParceira = 19;
    seqPessoaPerfilRoboTotem = 20;
    seqPessoaPerfilRoboPDVSite = 6270;

    /* SEQ */
    seqTabelaPrecoTotem = 42;
    seqTipoDocumento = 30;
    seqTipoContatoCelular = 1;
    seqTipoContatoWhatsApp = 3;
    seqTipoContatoEmail = 2;
    seqItemCategoriaCaracteristaItemTotem = 1;
    seqDocumentoItemCaracteristicaInstalacao = 3;
    seqDocumentoItemCaracteristicaManutencao = 4;
    seqTipoRelacionamentoFabricante = 5;
    seqTipoRelacionamentoCliente = 17;
    seqTipoRelacionamentoTecnicoRejeitado = 15;
    seqTipoRelacionamentoTecnicoAgendado = 6;
    seqTipoRelacionamentoTecnicoResponsavel = 20;
    seqTipoRelacionamentoSolicitante = 16;
    seqTipoRelacionamentoDependenteIexcombo = 29;
    seqTipoDocumentoProcessoPrimeiroAgendamento = 56;
    seqTipoDocumentoProcessoAgendada = 55;
    seqTipoDocumentoProcessoAguardandoPagamento = 51;
    seqTipoDocumentoProcessoTenicoRejeitado = 57;
    seqTipoDocumentoProcessoFinalizado = 58;
    seqTipoEnderecoEntrega = 4;
    seqTipoEnderecoCobranca = 5;
    seqContaFinanceiraReceber = 36;
    seqContaFinanceiraPagar = 38;
    seqFormaPagamentoPix = 57;
    seqContaBancaria = 24;
    seqTipoRelacionamentoBase = 21;

    seqPessoaCaracteristicaBase = 96;
    seqPessoaCaracteristicaIexcombo = 97;

    seqPessoaPerfilDependenteAIG = 36;

    /* ID */
    idListaContaBancaria = 'CB';
    idListaFormaPagamento = 'FP';
    idListaContaFinanceira = 'CF';

    /* STATUS */
    statusServicoNaoPago = -1;
    statusServicoDisponivel = 0;
    statusServicoAgendado = 1;
    statusServicoRejeitado = 2;
    statusServicoFinalizado = 3;
}