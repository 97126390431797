import { Component } from 'react';
import './style.css';

export default class TipoServico extends Component {

    componentDidMount() {
        console.log(this.props)
    }

    render() {
        return <div className="sis-dr-ts-container">
            <h2 className="sis-dr-ts-titulo">Tipos de serviço</h2>

            <div className='sis-dr-ts-wrapper'>
                <button
                    onClick={() => this.props.setState({ tipoServico: this.props.TIPOS_SERVICO.INSTALAR })}
                    className={this.props.tipoServico === this.props.TIPOS_SERVICO.INSTALAR ?
                        'sis-dr-ts-wrapper__botao sis-dr-ts-wrapper__selecionado' :
                        'sis-dr-ts-wrapper__botao'}>Instalação</button>
                <button
                    onClick={() => this.props.setState({ tipoServico: this.props.TIPOS_SERVICO.CONSERTAR })}
                    className={this.props.tipoServico === this.props.TIPOS_SERVICO.CONSERTAR ?
                        'sis-dr-ts-wrapper__botao sis-dr-ts-wrapper__selecionado' :
                        'sis-dr-ts-wrapper__botao'}>Manutenção</button>
            </div>
        </div>
    }
}