import { Component } from 'react';
import './style.css';

export default class EscolhaFabricante extends Component {
    render() {
        return <div className='sis-ch-ef-container'>
            <h2 className="sis-ch-ef-titulo">Escolha o fabricante</h2>

            <div className={this.props.mobile ? 'sis-ch-ef-wrapper--mobile' : 'sis-ch-ef-wrapper'}>
                {this.props.listaPessoaFabricante.map(item =>
                    <button
                        onClick={() => this.props.setState({ pessoaFabricante: item })}
                        className={this.props.pessoaFabricante === item && this.props.mobile ?
                            'sis-ch-ef-wrapper__botao--mobile sis-ch-ef-wrapper__selecionado' :
                            this.props.mobile ?
                                'sis-ch-ef-wrapper__botao--mobile' :
                                this.props.pessoaFabricante === item ?
                                    'sis-ch-ef-wrapper__botao sis-ch-ef-wrapper__selecionado' :
                                    'sis-ch-ef-wrapper__botao'
                        }
                    >
                        <img
                            src={this.props.urlBaseArquivo + item.pessoaRelacionadaFoto}
                            alt="Fabricante"
                            className={this.props.mobile ? 'sis-ch-ef-wrapper__imagem--mobile' : 'sis-ch-ef-wrapper__imagem'}
                        />
                    </button>)}
            </div>
        </div>
    }
}