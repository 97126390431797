import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";
import { Component } from "react";
import { HiEye } from "react-icons/hi";
import Constante from "../../../../util/Constante";
import Util from "../../../../util/Util";
import Header from "../Header";
import PessoaPg from "../Pessoas/components/PessoaPg";
import PessoaRelacionamento from 'lirida-back-service/Servicos/PessoaRelacionamento/PessoaRelacionamento'

import './style.css';

export default class Cadastro extends Component {
    constante = new Constante();
    util = new Util();
    pessoaService = new PessoaService(this.constante.token, this.constante.url_base);


    state = {
        pessoaUsuario: new Pessoa(),
        pessoa: new Pessoa(),
        pessoaBase: new Pessoa(),
        conteudo: '',
        urlBase: '',
        pessoaRelacionamento: new PessoaRelacionamento(),
        listaPessoaBase: [],
        listaPessoa: [],
        listaPessoaRelacionamento: [],
        novaPessoa: false,
        carregando: false,
    }

    componentDidMount() {
        this.iniciar();
    }

    async iniciar() {
        this.pessoaUsuario = await this.util.buscarLocalStorage('PESSOA_USUARIO');
        this.util.validarAcessoTela(this.constante.Telas.Cadastro, this.pessoaUsuario);

        this.urlBase = await this.util.buscarUrlBaseArquivo();

        this.setState({
            pessoaUsuario: this.pessoaUsuario,
            urlBase: this.urlBase,
        });

        this.listar();
    }

    async listar() {
        this.setState({
            listaCarregando: true,
            listaPessoa: [],
            listaPessoaBase: [],
        });

        this.retorno = await this.pessoaService.listar(
            this.constante.seqUsuario,
            this.state.conteudo,
            this.constante.seqPessoaPerfilOperacional,
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '');

        console.log(this.retorno);


        this.retornoPessoaBase = await this.pessoaService.listar(
            this.constante.seqUsuario,
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            this.constante.seqPessoaCaracteristicaBase);
        console.log(this.retornoPessoaBase)

        this.setState({
            listaCarregando: false,
            listaPessoa: this.retorno.objeto,
            listaPessoaBase: this.retornoPessoaBase.objeto,
        })
    }

    formatarDocumento(pTexto) {
        this.setState({ pessoa: { ...this.state.pessoa, documento: this.util.formatarCPFCNPJ(pTexto) } })
    }

    voltar() {
        this.setState({
            novaPessoa: false,
            pessoa: new Pessoa(),
            conteudo: ''
        })
    }

    buscarPessoaBase(pSeqPessoa) {
        this.listaPessoaBase = this.state.listaPessoaBase;
        this.pessoaBase = this.state.pessoaBase;

        for (let i = 0; i < this.listaPessoaBase.length; i++) {
            if (this.listaPessoaBase[i].seqPessoa === Number(pSeqPessoa)) {
                this.pessoaBase = this.listaPessoaBase[i]
            }
        }

        this.setState({
            pessoaBase: this.pessoaBase
        })
    }

    selecionarPessoa(pPessoa) {
        this.setState({ pessoa: pPessoa });
        this.listarRelacionamento(pPessoa);
    }

    async listarRelacionamento(pPessoa) {
        this.retornoRelacionamento = await this.pessoaService.listarRelacionamento(pPessoa);
        this.setState({ listaPessoaRelacionamento: this.retornoRelacionamento.objeto });

    }

    async salvarPessoaRelacionamento() {
        this.pessoa = this.state.pessoa;
        this.pessoaBase = this.state.pessoaBase;
        this.pessoaRelacionamento = this.state.pessoaRelacionamento;

        this.pessoaRelacionamento.seqUsuario = this.constante.seqUsuario;
        this.pessoaRelacionamento.seqPessoa = this.pessoa.seqPessoa;
        this.pessoaRelacionamento.seqPessoaRelacionada = this.pessoaBase.seqPessoa;
        this.pessoaRelacionamento.seqTipoRelacionamento = this.constante.seqTipoRelacionamentoBase;
        await this.pessoaService.salvarRelacionamento(this.pessoaRelacionamento);

        this.listarRelacionamento(this.pessoa);
    }


    async deletarRelacionamento(pPessoaRelacionamento) {
        this.retorno = window.confirm(`Deseja deletar ${pPessoaRelacionamento.pessoaRelacionadaNome}?`)
        if (this.retorno === false) {
            return
        }
        await this.pessoaService.deletarRelacionamento(pPessoaRelacionamento);
        this.listarRelacionamento(this.state.pessoa);
    }

    novo() {
        this.setState({
            novaPessoa: true,
            listaPessoaRelacionamento: [],
        })
    }

    marcarDesmarcar(pNumero) {
        this.pessoa = this.state.pessoa;
        switch (pNumero) {
            case 0:
                if (this.pessoa.tags0 === 'S') {
                    this.pessoa.tags0 = 'N'
                } else {

                    this.pessoa.tags0 = 'S';
                }
                break;
            case 1:
                if (this.pessoa.tags1 === 'S') {
                    this.pessoa.tags1 = 'N'
                } else {

                    this.pessoa.tags1 = 'S';
                }
                break;
            case 2:
                if (this.pessoa.tags2 === 'S') {
                    this.pessoa.tags2 = 'N'
                } else {

                    this.pessoa.tags2 = 'S';
                }
                break;
            case 3:
                if (this.pessoa.tags3 === 'S') {
                    this.pessoa.tags3 = 'N'
                } else {

                    this.pessoa.tags3 = 'S';
                }
                break;
                case 4:
                    if (this.pessoa.tags4 === 'S') {
                        this.pessoa.tags4 = 'N'
                    } else {
    
                        this.pessoa.tags4 = 'S';
                    }
                    case 5:
                        if (this.pessoa.tags5 === 'S') {
                            this.pessoa.tags5 = 'N'
                        } else {
        
                            this.pessoa.tags5 = 'S';
                        }
                break;
            default: break;
        }

        this.setState({
            pessoa: this.pessoa
        })
    }

    async salvar() {
        this.pessoa = this.state.pessoa;

        this.setState({ carregando: true });

        if (!this.pessoa.seqPessoa && this.state.novaPessoa) {
            this.pessoa.seqUsuario = this.constante.seqUsuario;
            this.pessoa.seqPessoaPerfil = this.constante.seqPessoaPerfilOperacional;
            this.retorno = await this.pessoaService.salvarNovaPessoaUsuario(this.pessoa);
        } else {
            this.retorno = await this.pessoaService.salvar(this.pessoa);
        }
        if (this.retorno.resultado === false) {
            window.alert(this.retorno.msg)
            this.setState({ carregando: false })
            return
        }
        this.pessoa = this.retorno.objeto;

        if (this.pessoa.seqPessoa === this.state.pessoaUsuario.seqPessoa) {
            this.util.salvarLocalStorage('PESSOA_USUARIO', this.pessoa);
            // this.setState({ pessoaUsuario: this.pessoa })
        }

        this.setState({
            pessoa: this.pessoa,
            carregando: false,
            novaPessoa: false,
        })

        window.alert(this.retorno.msg);
    }

    render() {
        return this.state.pessoaUsuario.seqPessoa && <div className="sis-cad-container">

            <Header
                pessoaUsuario={this.state.pessoaUsuario}
                selecionado={this.constante.Telas.Cadastro} />

            {(this.state.pessoa.seqPessoa || this.state.novaPessoa) ?
                <PessoaPg
                    setState={pState => this.setState(pState)}
                    pessoa={this.state.pessoa}
                    novaPessoa={this.state.novaPessoa}
                    pessoaBase={this.state.pessoaBase}
                    salvar={() => this.salvar()}
                    carregando={this.state.carregando}
                    listaPessoaBase={this.state.listaPessoaBase}
                    adicionarRelacionamento={() => this.adicionarRelacionamento()}
                    buscarPessoaBase={(pSeqPessoa) => this.buscarPessoaBase(pSeqPessoa)}
                    salvarPessoaRelacionamento={() => this.salvarPessoaRelacionamento()}
                    listaPessoaRelacionamento={this.state.listaPessoaRelacionamento}
                    deletarRelacionamento={(pPessoaRelacionamento) => this.deletarRelacionamento(pPessoaRelacionamento)}
                    formatarDocumento={(pTexto) => this.formatarDocumento(pTexto)}
                    marcarDesmarcar={(pNumero) => this.marcarDesmarcar(pNumero)}
                /> : <main className="sis-cad-main">
                    <div className="sis-cad-wrapper">
                        <div>
                            <div>
                                <label>Pesquisar</label>
                            </div>
                            <input onChange={(pTexto) => this.setState({ conteudo: pTexto.target.value })} />
                        </div>
                        <button
                            className="sis-cad-botao"
                            onClick={() => this.listar()}
                        >
                            Buscar
                        </button>
                        <button
                            className="sis-cad-botao"
                            onClick={() => this.novo()}
                        >
                            Novo
                        </button>
                    </div>

                    <table className="sis-cad-main__tabela">
                        <thead className="sis-cad-main__thead">
                            <tr>
                                <th className="sis-cad-main__th">id</th>
                                <th className="sis-cad-main__th">Nome</th>
                                <th className="sis-cad-main__th">Email</th>
                                <th className="sis-cad-main__th">Ação</th>
                            </tr>
                        </thead>

                        <tbody>
                            {this.state.listaPessoa.map(pPessoa =>
                                <tr>
                                    <td className="sis-cad-main__td">{pPessoa.seqPessoa}</td>
                                    <td className="sis-cad-main__td">{pPessoa.nome}</td>
                                    <td className="sis-cad-main__td">{pPessoa.emailPlataformaLirida
                                    }</td>
                                    <td className="sis-cad-main__td">
                                        <button
                                            className="sis-cad-botao-detalhe"
                                            onClick={() => this.selecionarPessoa(pPessoa)}
                                        >
                                            <HiEye />
                                        </button>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </main>
            }
        </div>
    }
}