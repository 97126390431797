import { Component } from 'react';
import './style.css';
import instalador from '../../../../assets/instalador.jpg';

export default class Porque extends Component {
    render() {
        return <div className='hs-por-container'>
            <div className='hs-por-img-container'>
                <img
                    src={instalador}
                    className='hs-por-img-container__imagem'
                    alt="Técnico instalando um ar condicionado"
                />
            </div>

            <h2 className='hs-por-titulo'>Porque a IEX?</h2>

            <ul>
                <li className='hs-por-lista__item'>Atendimento</li>
                <li className='hs-por-lista__item'>Acompanhamento</li>
                <li className='hs-por-lista__item'>Suporte</li>
            </ul>
        </div>
    }
}