import { Component } from 'react';
import './style.css';

export default class TipoServico extends Component {

    componentDidMount() {
        console.log(this.props)
    }

    render() {
        return <div className="sis-ch-ts-container">
            <h2 className="sis-ch-ts-titulo">Tipos de serviço</h2>

            <div className={this.props.mobile ?
                'sis-ch-ts-wrapper--mobile' :
                'sis-ch-ts-wrapper'
            }>
                <button
                    onClick={() => this.props.setState({ tipoServico: this.props.TIPOS_SERVICO.INSTALAR })}
                    className={
                        this.props.tipoServico === this.props.TIPOS_SERVICO.INSTALAR &&
                            this.props.mobile ?
                            'sis-ch-ts-wrapper__botao--mobile sis-ch-ts-wrapper__selecionado' :
                            this.props.mobile ?
                                'sis-ch-ts-wrapper__botao--mobile' :
                                this.props.tipoServico === this.props.TIPOS_SERVICO.INSTALAR ?
                                    'sis-ch-ts-wrapper__botao sis-ch-ts-wrapper__selecionado' :
                                    'sis-ch-ts-wrapper__botao'}
                >
                    Instalação
                </button>
                <button
                    onClick={() => this.props.setState({ tipoServico: this.props.TIPOS_SERVICO.CONSERTAR })}
                    className={
                        this.props.tipoServico === this.props.TIPOS_SERVICO.CONSERTAR &&
                            this.props.mobile ?
                            'sis-ch-ts-wrapper__botao--mobile sis-ch-ts-wrapper__selecionado' :
                            this.props.mobile ?
                                'sis-ch-ts-wrapper__botao--mobile' :
                                this.props.tipoServico === this.props.TIPOS_SERVICO.CONSERTAR ?
                                    'sis-ch-ts-wrapper__botao sis-ch-ts-wrapper__selecionado' :
                                    'sis-ch-ts-wrapper__botao'}
                >
                    Manutenção
                </button>
            </div>
        </div>
    }
}