import { Component } from 'react';
import './style.css';

export default class DadosPessoais extends Component {
    render() {
        return <div className='sis-dr-dp-container'>
            <h2 className='sis-dr-dp-titulo'>Dados pessoais</h2>

            <div className='sis-dr-dp-wrapper'>
                <div className='sis-dr-dp-input-wrapper'>
                    <label className='sis-dr-dp-label'>Seu nome</label>
                    <input
                        className='sis-dr-dp-input'
                        onChange={pTexto => this.props.setState({
                            pessoa: {
                                ...this.props.pessoa,
                                nome: pTexto.target.value
                            }
                        })}
                        value={this.props.pessoa.nome}
                    />
                </div>
                <div className='sis-dr-dp-input-wrapper'>
                    <label className='sis-dr-dp-label'>CPF / CNPJ</label>
                    <input
                        className='sis-dr-dp-input'
                        type="text"
                        onChange={pTexto => this.props.formatarPessoaDocumento(pTexto.target.value)}
                        value={this.props.pessoa.documento}
                    />
                </div>
                <div className='sis-dr-dp-input-wrapper'>
                    <label className='sis-dr-dp-label'>Telefone</label>
                    <input
                        className='sis-dr-dp-input'
                        type="tel"
                        onChange={pTexto => this.props.formatarPessoaTelefone(pTexto.target.value)}
                        value={this.props.pessoa.telefonePlataformaLirida}
                    />
                </div>
            </div>
        </div>
    }
}