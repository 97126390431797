import { Component } from 'react';
import './style.css';

export default class EscolhaProduto extends Component {
    render() {
        return <div className='sis-ch-ep-container'>
            <h2 className="sis-ch-ep-titulo">Escolha o produto</h2>

            <div className='sis-ch-ep-wrapper'>
                {this.props.listaProduto.map(item =>
                    <button
                        onClick={() => this.props.setState({ produto: item })}
                        className={this.props.produto === item && this.props.mobile ?
                            'sis-ch-ep-wrapper__botao--mobile sis-ch-ep-wrapper__selecionado' :
                            this.props.mobile ?
                                'sis-ch-ep-wrapper__botao--mobile' :
                                this.props.produto === item ?
                                    'sis-ch-ep-wrapper__botao sis-ch-ep-wrapper__selecionado' :
                                    'sis-ch-ep-wrapper__botao'
                        }
                    >
                        <img
                            src={this.props.urlBaseArquivo + item.categoriaImagem}
                            alt={item.categoriaNome}
                            className={this.props.mobile ? 'sis-ch-ep-wrapper__imagem--mobile' :
                                'sis-ch-ep-wrapper__imagem'}
                        />
                    </button>)}
            </div>
        </div>
    }
}