import { Component } from 'react';
import './style.css';

export default class Sobre extends Component {
    render() {
        return <div className='hs-sob-container'>
            <h2 className='hs-sob-titulo'>Simplicidade e flexibilidade</h2>

            <h3 className='hs-sob-subtitulo'>
                <span className='hs-sob-subitulo__span'>Instaladores credênciados</span>, instalação com <span className='hs-sob-subitulo__span'>garantia</span> e <span className='hs-sob-subitulo__span'>segurança</span> para realizar o seu serviço!
            </h3>

            <div className='hs-sob-card'>
                <h2 className='hs-sob-card__titulo'>Peça uma instalação</h2>
                <h3 className='hs-sob-card__subtitulo'>
                    Comprou e ainda não instalou o seu produto? Peça já a instalação mais rápida do Brasil!
                </h3>

                <p className='hs-sob-card__texto'>Pedir agora</p>
            </div>

            <div className='hs-sob-card'>
                <h2 className='hs-sob-card__titulo'>Peça uma manutenção</h2>
                <h3 className='hs-sob-card__subtitulo'>
                    Já possui o produto instalado e o mesmo apresenta problemas? Peça a melhor manutenção do Brasil!
                </h3>

                <p className='hs-sob-card__texto'>Pedir agora</p>
            </div>
        </div>
    }
}