import {Component} from 'react';
import './style.css';
import logoBranca from '../../../../assets/logo-branca-icone-texto.png';

export default class Footer extends Component {
    render() {
        return <div className='hs-fo-container'>
            <img src={logoBranca} className='hs-fo-imagem' alt="Logo secundária IEX Brasil"/>
        </div>
    }
}