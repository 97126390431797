import Pesquisa from 'lirida-back-service/Servicos/Pesquisa/Pesquisa';
import Util_api from 'lirida-back-service/Servicos/Util/Util';
import Constante from './Constante';

export default class Util {
  constante = new Constante();
  util_api = new Util_api(this.constante.token, this.constante.url_base);

  async buscarUrlBaseArquivo() {
    let retorno = await this.util_api.buscarUrlBaseArquivo();
    retorno = await retorno.json();
    return retorno;
  }

  async buscarUrlBaseFinanceiro() {
    let retorno = await this.util_api.buscarUrlBaseFinanceiro();
    retorno = await retorno.json();
    return retorno;
  }

  async buscarLocalStorage(pCampo) {
    let retorno = localStorage.getItem(pCampo);
    retorno = await JSON.parse(retorno);
    return retorno;
  }

  salvarLocalStorage(pCampo, pValor) {
    let valor = JSON.stringify(pValor);
    localStorage.setItem(pCampo, valor);
    console.log(pCampo + ' salvo localstorage com sucesso')
  }

  async removerLocalStorage(pCampo) {
    let retorno = localStorage.removeItem(pCampo);
    return retorno;
  }

  formatarTelefone(value) {
    let r = value.replace(/\D/g, '');
    r = r.replace(/^0/, '');

    if (r.length > 11) {
      r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, '($1) $2-$3');
    } else if (r.length > 7) {
      r = r.replace(/^(\d\d)(\d{5})(\d{0,4}).*/, '($1) $2-$3');
    } else if (r.length > 2) {
      r = r.replace(/^(\d\d)(\d{0,5})/, '($1) $2');
    } else if (value.trim() !== '') {
      r = r.replace(/^(\d*)/, '($1');
    }

    return r;
  }

  formatarData(pData) {
    if (!pData) {
      this.data = '';
    } else {
      if (String(pData).includes('T')) {
        this.data = pData;
        this.data = String(this.data).split('T')[0];
        this.data = this.data.replace('-', '/').replace('-', '/')
      }
    }

    return this.data;
  }

  formatarDataInput(pData) {
    if (String(pData).includes('/')) {
      this.data = String(pData).split('/').reverse().join('-');
    } else if (String(pData).includes('-')) {
      this.data = String(pData).split('-').reverse().join('/');
    } else {
      this.data = pData;
    }

    return this.data;
  }

  formatarCPFCNPJ(pTexto) {
    let t = pTexto;

    if (t.length > 15) {
      return t.replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})/, '$1/$2')
        .replace(/(\d{4})(\d{1,2})/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1')
    }

    if (t.length <= 15) {
      return t.replace(/\D/g, '')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    }
  }

  formatarValor(value) {
    if (value) {
      this.valorFormatado = Number(value).toLocaleString(
        'pt-BR',
        { style: 'currency', currency: 'BRL' }
      );
    } else {
      this.valorFormatado = Number(0).toLocaleString(
        'pt-BR',
        { style: 'currency', currency: 'BRL' }
      );
    }
    return this.valorFormatado;
  };

  formatarCEP(pTexto) {
    this.texto = pTexto;
    return this.texto.replace(/\D/g, '')
      .replace(/(\d{5})(\d)/, '$1-$2')
      .replace(/(-\d{3})\d+?$/, '$1')
  }

  async validarDocumento(pTipoDocumento, pDocumento) {
    this.retorno = await this.util_api.validarDocumento(pTipoDocumento, pDocumento);
    this.retorno = await this.retorno.json();
    return this.retorno;
  }

  retornaPesquisa(pCampo, pConteudo, pOperacao, pTipo) {
    let pesquisa = new Pesquisa();

    pesquisa.campo = pCampo;
    pesquisa.conteudo = pConteudo;
    pesquisa.operacao = pOperacao;
    pesquisa.tipo = pTipo;

    return pesquisa;
  }

  async listarLista(pSeqUsuario, pID) {
    this.retorno = await this.util_api.listarLista(pSeqUsuario, pID);
    this.retorno = this.retorno.json();
    return this.retorno;
  }


  validarAcessoTela(pTela, pPessoaUsuario) {
    this.pessoaUsuario = pPessoaUsuario;
    let retorno = true;

    function acessoNegado() {
      window.alert('ACESSO NEGADO!');
      window.history.back();
      retorno = false;
    }

    if (!this.pessoaUsuario) {
      acessoNegado();
      return
    }


    switch (pTela) {
      case this.constante.Telas.Documento:
        if (this.pessoaUsuario.tags0 !== 'S') {
          acessoNegado();
        }
        break;

      case this.constante.Telas.DigitacaoRapida:
        if (this.pessoaUsuario.tags1 !== 'S') {
          acessoNegado();
        }
        break;

      case this.constante.Telas.Financeiro:
        if (this.pessoaUsuario.tags2 !== 'S') {
          acessoNegado();
        }
        break;

      case this.constante.Telas.MapaDocumento:
        if (this.pessoaUsuario.tags3 !== 'S') {
          acessoNegado();
        }
        break;

      case this.constante.Telas.Cadastro:
        if (this.pessoaUsuario.tags4 !== 'S') {
          acessoNegado();
        }
        break;
      default: break;
    }

    return retorno;
  }
}