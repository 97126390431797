import { Component } from "react";
import Hotsite from './pages/Hotsite';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import DigitacaoRapida from './pages/Sistema/components/DigitacaoRapida';
import Documentos from './pages/Sistema/components/Documentos';
import Pessoas from './pages/Sistema/components/Pessoas';
import Financeiros from './pages/Sistema/components/Financeiros';
import Checkout from './pages/Sistema/components/Checkout';
import Endpoint from './pages/Sistema/components/Endpoint';
import Execucao from './pages/Sistema/components/Execucao';
import Mapa from './pages/Sistema/components/Mapa';
import MapaDois from './pages/Sistema/components/MapaDois';
import MapaDocumento from './pages/Sistema/components/MapaDocumento';
import Cadastro from './pages/Sistema/components/Cadastro';
import Util from "./util/Util";
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import Iexcombo from "./pages/Sistema/components/Iexcombo";


export default class Router extends Component {

    render() {
        return (
            <BrowserRouter>
                <Routes>
                    <Route path="*" element={<Navigate to="/" />} />
                    <Route path="/" element={<Hotsite />} />

                    <Route path="/documento" element={<Documentos />} />

                    <Route path="/digitacao" element={<DigitacaoRapida />} />

                    <Route path="/financeiro" element={<Financeiros />} />

                    <Route path="/mapa/documento" element={<MapaDocumento />} />

                    <Route path="/cadastro" element={<Cadastro />} />
                    {/* <Route path="/pessoa" element={<Pessoas />} /> */}


                    <Route path="/checkout" element={<Checkout />} />

                    <Route path="/api" element={<Endpoint />} />

                    <Route path="/execucao" element={<Execucao />} />

                    <Route path="/mapa" element={<Mapa />} />

                    <Route path="/mapa-os" element={<MapaDois />} />

                    <Route path="/iexcombo" element={<Iexcombo />} />
                </Routes>
            </BrowserRouter>
        )
    }
}