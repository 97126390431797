import { Component, createRef } from 'react';
import PessoaService from 'lirida-back-service/Servicos/Pessoa/PessoaService';
import Constante from '../../../../util/Constante';
import Util from '../../../../util/Util';
import DocumentoService from 'lirida-back-service/Servicos/Documento/DocumentoService';

export default class MapaDois extends Component {
    documentoService = new DocumentoService();
    util = new Util();
    constante = new Constante();

    mapaRef = createRef();

    coordenadasBrasil = {
        lat: -14.2401,
        lng: -53.1805,
    }

    async componentDidMount() {
        const map = new window.google.maps.Map(this.mapaRef.current, {
            center: this.coordenadasBrasil,
            zoom: 4,
        });

        this.iniciar(map);
    }

    async iniciar(pMap) {
        const map = pMap;

        this.listaDocumento = await this.listar();
        console.log(this.listaDocumento);

        for (let i = 0; i < this.listaDocumento.length; i++) {

            if (this.listaDocumento[i].endLatitude && this.listaDocumento[i].endLongitude) {
                console.log('boa')
                const contentString =
                    "</div>" +
                    '<h2>' + this.listaDocumento[i].seqDocumento + '</h2>' +
                    '<h3>' + this.listaDocumento[i].pessoaNome + '</h3>' +
                    "</div>";

                const infowindow = new window.google.maps.InfoWindow({
                    content: contentString,
                    maxWidth: 200,
                });

                const marker = new window.google.maps.Marker({
                    position: {
                        lat: Number(this.listaDocumento[i].endLatitude),
                        lng: Number(this.listaDocumento[i].endLongitude),
                    },
                    map,
                })


                marker.addListener("click", () => {
                    infowindow.open({
                        anchor: marker,
                        map,
                        shouldFocus: false,
                    });
                });
            }
        }

        setTimeout(() => this.iniciar(), 10000)
    }

    async listar() {
        this.retornoListaDocumento = await this.documentoService.listar(this.constante.seqUsuario);
        this.listaDocumento = this.retornoListaDocumento.objeto;
        return this.listaDocumento;
    }

    render() {
        return <div ref={this.mapaRef} style={{ width: '100%', height: '100vh' }}></div>
    }
}