import { Component } from 'react';
import './style.css';
import logo from '../../../../assets/logo-icone.png';

export default class Login extends Component {
    render() {
        return <div className='log-container'>
            <div className='log-wrapper'>
                <h2 className='log-wrapper__titulo'>Acesso</h2>

                <div className='log-blocos'>
                    <div className='log-bloco1'>
                        <button
                            // onClick={() => this.props.mostrarLogin()}
                            className='log-wrapper__logo-botao'
                        >
                            <img src={logo} alt="IEX" className='log-wrapper__logo' />
                        </button>
                    </div>

                    <div className='log-bloco2'>
                        <form
                            className='log-wrapper__form'
                            onSubmit={pSubmit => this.props.login(pSubmit.preventDefault())}
                        >
                            <div className='log-wrapper__div'>
                                <label className='log-wrapper__label'>Usuário</label>
                                <input
                                    id="usuario"
                                    className='log-wrapper__input'
                                    type="text"
                                    onChange={pTexto =>
                                        this.props.setState({ pessoaUsuario: pTexto.target.value })
                                    }
                                    value={this.props.usuario}
                                />
                            </div>

                            <div className='log-wrapper__div'>
                                <label className='log-wrapper__label'>Senha</label>
                                <input
                                    id="senha"
                                    className='log-wrapper__input'
                                    type="password"
                                    onChange={pTexto =>
                                        this.props.setState({ senha: pTexto.target.value })
                                    }
                                    value={this.props.senha}
                                />
                            </div>

                            <button
                                type='button'
                                className='log-wrapper_botao_recuperarSenha'
                                onClick={() => this.props.recuperarSenha()}>
                                Recuperar senha
                            </button>

                            <button className='log-wrapper__botao' type='submit'>
                                Entrar
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    }
}