import Documento from 'lirida-back-service/Servicos/Documento/Documento';
import DocumentoItem from 'lirida-back-service/Servicos/Documento/DocumentoItem';
import DocumentoService from 'lirida-back-service/Servicos/Documento/DocumentoService';
import DocumentoItemCaracteristicaDocumentoItem from 'lirida-back-service/Servicos/DocumentoItemCaracteristicaDocumentoItem/DocumentoItemCaracteristicaDocumentoItem';
import DocumentoItemRelacionamento from 'lirida-back-service/Servicos/DocumentoItemRelacionamento/DocumentoItemRelacionamento';
import DocumentoRelacionamento from 'lirida-back-service/Servicos/DocumentoRelacionamento/DocumentoRelacionamento';
import Endereco from 'lirida-back-service/Servicos/Endereco/Endereco';
import GoogleAPI from 'lirida-back-service/Servicos/Google/GoogleAPI';
import ItemService from 'lirida-back-service/Servicos/Item/ItemService';
import ItemCategoriaCaracteristicaItem from 'lirida-back-service/Servicos/ItemCategoriaCaracteristicaItem/ItemCategoriaCaracteristicaItem';
import ItemCategoriaCaracteristicaItemService from 'lirida-back-service/Servicos/ItemCategoriaCaracteristicaItem/ItemCategoriaCaracteristicaItemService';
import ItemRelacionamento from 'lirida-back-service/Servicos/ItemRelacionamento/ItemRelacionamento';
import Pessoa from 'lirida-back-service/Servicos/Pessoa/Pessoa';
import PessoaService from 'lirida-back-service/Servicos/Pessoa/PessoaService';
import TabelaPreco from 'lirida-back-service/Servicos/TabelaPreco/TabelaPreco';
import TabelaPrecoService from 'lirida-back-service/Servicos/TabelaPreco/TabelaPrecoService';
import CepService from 'lirida-back-service/Servicos/Cep/CepService';
import Cep from 'lirida-back-service/Servicos/Cep/Cep';
import { Component } from 'react';
import Constante from '../../../../util/Constante';
import Util from '../../../../util/Util';
import Header from '../Header';
import DadosEndereco from './components/DadosEndereco';
import DadosPessoais from './components/DadosPessoais';
import DataAgendamento from './components/DataAgendamento';
import EscolhaCategoria from './components/EscolhaCategoria';
import EscolhaFabricante from './components/EscolhaFabricante';
import EscolhaProduto from './components/EscolhaProduto';
import Finalizado from './components/Finalizado';
import Pagamento from './components/Pagamento';
import ResumoCompra from './components/ResumoCompra';
import TipoServico from './components/TipoServico';
import './style.css';
import { MdClose } from 'react-icons/md';
import FinanceiroService from 'lirida-back-service/Servicos/Financeiro/FinanceiroService';
import Financeiro from '../Financeiros';
import WhatsAppService from '../../../../util/WhatsAppService';

export default class DigitacaoRapida extends Component {
    /* INSTÂNCIA DOS OBJETOS */

    util = new Util();
    constante = new Constante();
    googleApi = new GoogleAPI(this.constante.token, this.constante.url_base);
    pessoaService = new PessoaService(this.constante.token, this.constante.url_base);
    tabelaPrecoService = new TabelaPrecoService(this.constante.token, this.constante.url_base);
    documentoService = new DocumentoService(this.constante.token, this.constante.url_base);
    itemCategoriaCaracteristicaItemService = new ItemCategoriaCaracteristicaItemService(this.constante.token, this.constante.url_base);
    itemService = new ItemService(this.constante.token, this.constante.url_base);
    cepService = new CepService(this.constante.token, this.constante.url_base);
    financeiroService = new FinanceiroService(this.constante.token, this.constante.url_base);
    whatsappService = new WhatsAppService(this.constante.token, this.constante.url_base);

    /* ENUMS */

    TELAS = {
        INICIO: 'INICIO',
        TIPO_SERVICO: 'TIPO_SERVICO',
        ESCOLHA_PRODUTO: 'ESCOLHA_PRODUTO',
        ESCOLHA_CATEGORIA: 'ESCOLHA_CATEGORIA',
        ESCOLHA_FABRICANTE: 'ESCOLHA_FABRICANTE',
        RESUMO_COMPRA: 'RESUMO_COMPRA',
        DADOS_PESSOAIS: 'DADOS_PESSOAIS',
        DADOS_ENDERECO: 'DADOS_ENDERECO',
        DATA_AGENDAMENTO: 'DATA_AGENDAMENTO',
        PAGAMENTO: 'PAGAMENTO',
        FINALIZADO: 'FINALIZADO',
    }

    TIPOS_SERVICO = {
        INSTALAR: this.constante.seqDocumentoItemCaracteristicaInstalacao,
        CONSERTAR: this.constante.seqDocumentoItemCaracteristicaManutencao,
    }

    FORMAS_PAGAMENTO = {
        PIX: this.constante.seqFormaPagamentoPix,
    }

    /* STATE */

    state = {
        /* TELA */
        tela: this.TELAS.INICIO,
        telaRetorno: null,

        /* OBJETO */
        urlBaseArquivo: '',
        tipoServico: null,
        categoria: null,
        formaPagamento: null,
        fazerPopAoVoltar: false,

        /* OBJETO LIRIDA */
        pessoaUsuario: new Pessoa(),
        pessoa: new Pessoa(),
        pessoaFabricante: new Pessoa(),
        endereco: new Endereco(),
        documento: new Documento(),
        documentoItem: new DocumentoItem(),
        tabelaPreco: new TabelaPreco(),
        itemCategoriaCaracteristicaItem: new ItemCategoriaCaracteristicaItem(),
        itemRelacionamento: new ItemRelacionamento(),

        /* LISTA */
        listaProduto: [],
        listaCategoria: [],
        listaItemCategoriaCaracteristicaItem: [],
        listaTabelaPreco: [],
        listaPessoaFabricante: [],
        listaDocumentoItem: [],

        /* Modal */
        vModalAddItem: false,
    }

    componentDidMount() {
        this.iniciar();
    }

    async iniciar() {
        this.pessoaUsuario = await this.util.buscarLocalStorage('PESSOA_USUARIO');

        this.util.validarAcessoTela(this.constante.Telas.DigitacaoRapida, this.pessoaUsuario);

        this.urlBaseArquivo = await this.util.buscarUrlBaseArquivo();
        this.retornoItemCategoriaCaracteristicaItem = await this.itemCategoriaCaracteristicaItemService.listarPorSeqItemCategoriaCaracteristicaItem(this.constante.seqItemCategoriaCaracteristaItemTotem)

        this.setState({
            /* TELA */
            tela: this.TELAS.TIPO_SERVICO,
            telaRetorno: null,

            /* OBJETO */
            urlBaseArquivo: this.urlBaseArquivo,
            tipoServico: null,
            produto: null,
            categoria: null,
            formaPagamento: null,

            /* OBJETO LIRIDA */
            pessoaUsuario: this.pessoaUsuario,
            pessoa: new Pessoa(),
            endereco: new Endereco(),
            documento: new Documento(),
            documentoItem: new DocumentoItem(),
            tabelaPreco: new TabelaPreco(),
            itemCategoriaCaracteristicaItem: new ItemCategoriaCaracteristicaItem(),

            /* LISTA */
            listaItemCategoriaCaracteristicaItem: this.retornoItemCategoriaCaracteristicaItem.objeto,
            listaProduto: [],
            listaCategoria: [],
            listaCompra: [],
            listaTabelaPreco: [],
            listaPessoaFabricante: [],
            listaDocumentoItem: [],
        })
    }

    voltar() {
        switch (this.state.tela) {
            case this.TELAS.TIPO_SERVICO:
                this.setState({ tela: this.TELAS.INICIO });
                break;

            case this.TELAS.ESCOLHA_PRODUTO:
                this.setState({ tela: this.TELAS.TIPO_SERVICO });
                break;

            case this.TELAS.ESCOLHA_CATEGORIA:
                this.setState({ tela: this.TELAS.ESCOLHA_PRODUTO });
                break;

            case this.TELAS.ESCOLHA_FABRICANTE:
                this.setState({ tela: this.TELAS.ESCOLHA_CATEGORIA });
                break;

            case this.TELAS.RESUMO_COMPRA:
                if (this.state.fazerPopAoVoltar === true) {
                    this.listaDocumentoItem = this.state.listaDocumentoItem;
                    this.listaDocumentoItem.pop();
                    this.setState({
                        listaDocumentoItem: this.listaDocumentoItem,
                        fazerPopAoVoltar: false,
                    });
                }
                this.setState({ tela: this.state.telaRetorno });
                break;

            case this.TELAS.DADOS_PESSOAIS:
                this.setState({ tela: this.TELAS.RESUMO_COMPRA });
                break;

            case this.TELAS.DADOS_ENDERECO:
                this.setState({ tela: this.TELAS.DADOS_PESSOAIS });
                break;

            case this.TELAS.DATA_AGENDAMENTO:
                this.setState({ tela: this.TELAS.DADOS_ENDERECO });
                break;

            case this.TELAS.PAGAMENTO:
                this.setState({ tela: this.TELAS.DATA_AGENDAMENTO });
                break;

            default:
                this.setState({ tela: this.TELAS.INICIO });
        }
    }

    proximo() {
        switch (this.state.tela) {
            case this.TELAS.TIPO_SERVICO:
                this.salvarTipoServico(this.state.tipoServico);
                break;

            case this.TELAS.ESCOLHA_PRODUTO:
                this.salvarProduto(this.state.produto);
                break;

            case this.TELAS.ESCOLHA_CATEGORIA:
                this.salvarCategoria(this.state.categoria);
                break;

            case this.TELAS.ESCOLHA_FABRICANTE:
                this.salvarPessoaFabricante(this.state.pessoaFabricante);
                break;

            case this.TELAS.RESUMO_COMPRA:
                this.alerta_adicionarItem();
                break;

            case this.TELAS.DADOS_PESSOAIS:
                this.salvarDadosPessoais();
                break;

            case this.TELAS.DADOS_ENDERECO:
                this.salvarDadosEndereco();
                break;

            case this.TELAS.DATA_AGENDAMENTO:
                this.salvarDataAgendamento();
                break;

            case this.TELAS.PAGAMENTO:
                this.finalizar();
                break;

            default:
                this.setState({ tela: this.TELAS.INICIO });
        }
    }

    salvarTipoServico(pTipoServico) {
        this.tipoServico = pTipoServico;
        this.setState({
            documentoItem: {
                ...this.state.documentoItem,
                tagn0: this.tipoServico,
            },
            tela: this.TELAS.ESCOLHA_PRODUTO,
        });
    }

    async salvarProduto(pProduto) {
        this.itemCategoriaCaracteristicaItem = pProduto;
        this.retornoTabelaPreco = await this.tabelaPrecoService.listarTabelaPreco(this.constante.seqTabelaPrecoTotem, this.itemCategoriaCaracteristicaItem.seqItemCategoria, 'S');
        this.setState({
            itemCategoriaCaracteristicaItem: this.produto,
            listaTabelaPreco: this.retornoTabelaPreco.objeto,
            tela: this.TELAS.ESCOLHA_CATEGORIA
        })
    }

    async salvarCategoria(pCategoria) {
        this.categoria = pCategoria;
        this.retornoItemRelacionamento = await this.itemService.listarRelacionamentoItem(this.categoria.seqItem, this.constante.seqTipoRelacionamentoFabricante)
        this.setState({
            listaPessoaFabricante: this.retornoItemRelacionamento.objeto,
            tabelaPreco: this.categoria,
            tela: this.TELAS.ESCOLHA_FABRICANTE
        })
    }
    async salvarPessoaFabricante(pItemRelacionamento) {
        this.documentoItem = this.state.documentoItem;
        this.listaDocumentoItem = this.state.listaDocumentoItem;

        this.documentoItem.seqItem = this.state.tabelaPreco.seqItem;
        this.documentoItem.quantidade = 1;
        this.documentoItem.itemNome = this.state.tabelaPreco.itemNome;
        this.documentoItem.precoUnitario = this.state.tabelaPreco.preco;
        this.documentoItem.precoTabela = this.state.tabelaPreco.preco;
        this.documentoItem.precoTabelaMaximo = this.state.tabelaPreco.precoMaximo;
        this.documentoItem.precoTabelaMinimo = this.state.tabelaPreco.precoMinimo;
        this.documentoItem.tagn1 = pItemRelacionamento.seqPessoaRelacionada;

        this.listaDocumentoItem.push(this.documentoItem);

        this.verCarrinho();

        this.setState({
            fazerPopAoVoltar: true,
            listaDocumentoItem: this.listaDocumentoItem,
            documentoItem: this.documentoItem,
            itemRelacionamento: pItemRelacionamento,
            tela: this.TELAS.RESUMO_COMPRA,
        })
    }

    diminuirQuantidadeDocumentoItem(pItem) {
        if (pItem?.quantidade <= 1) {
            return;
        }

        this.documentoItem = pItem;
        this.listaDocumentoItem = this.state.listaDocumentoItem;

        for (let i = 0; i < this.listaDocumentoItem.length; i++) {
            if (this.documentoItem === this.listaDocumentoItem[i]) {
                this.listaDocumentoItem[i].quantidade = this.listaDocumentoItem[i].quantidade - 1
            }
        }

        this.setState({
            listaDocumentoItem: this.listaDocumentoItem
        })
    }
    aumentarQuantidadeDocumentoItem(pItem) {
        this.documentoItem = pItem;
        this.listaDocumentoItem = this.state.listaDocumentoItem;

        for (let i = 0; i < this.listaDocumentoItem.length; i++) {
            if (this.documentoItem === this.listaDocumentoItem[i]) {
                this.listaDocumentoItem[i].quantidade = this.listaDocumentoItem[i].quantidade + 1
            }
        }

        this.setState({
            listaDocumentoItem: this.listaDocumentoItem
        })
    }

    formatarEnderecoCep(pTexto) {
        this.retornoCepFormatado = this.util.formatarCEP(pTexto);
        this.setState({
            endereco: {
                ...this.state.endereco,
                cep: this.retornoCepFormatado,
            }
        })
    }

    async buscarCEP() {
        this.cep = new Cep();

        this.retornoCep = await this.cepService.buscarCep(String(this.state.endereco.cep));

        this.cep = this.retornoCep;

        this.setState({
            endereco: {
                ...this.state.endereco,
                logradouro: this.cep.logradouro,
                bairro: this.cep.bairro,
                cidade: this.cep.localidade,
                complemento: this.cep.complemento,
                estado: this.cep.estado,
                uf: this.cep.uf,
            }
        })
    }

    verCarrinho() {
        this.setState({
            telaRetorno: this.state.tela,
            tela: this.TELAS.RESUMO_COMPRA,
        })
    }

    reiniciarAplicacao() {
        this.setState({
            /* TELA */
            tela: this.TELAS.INICIO,
            telaRetorno: null,

            /* OBJETO */
            urlBaseArquivo: '',
            tipoServico: null,
            categoria: null,
            formaPagamento: null,
            fazerPopAoVoltar: false,

            /* OBJETO LIRIDA */
            pessoa: new Pessoa(),
            pessoaFabricante: new Pessoa(),
            endereco: new Endereco(),
            documento: new Documento(),
            documentoItem: new DocumentoItem(),
            tabelaPreco: new TabelaPreco(),
            itemCategoriaCaracteristicaItem: new ItemCategoriaCaracteristicaItem(),
            itemRelacionamento: new ItemRelacionamento(),

            /* LISTA */
            listaProduto: [],
            listaCategoria: [],
            listaItemCategoriaCaracteristicaItem: [],
            listaTabelaPreco: [],
            listaPessoaFabricante: [],
            listaDocumentoItem: [],
        })

        this.iniciar();
    }

    deletarItem(pItem) {
        this.documentoItem = pItem;
        this.listaDocumentoItem = this.state.listaDocumentoItem;

        this.listaDocumentoItem = this.listaDocumentoItem.filter(item => item !== this.documentoItem);
        this.setState({ listaDocumentoItem: this.listaDocumentoItem });
    }

    async adicionarItem() {
        this.urlBaseArquivo = await this.util.buscarUrlBaseArquivo();
        this.retornoItemCategoriaCaracteristicaItem = await this.itemCategoriaCaracteristicaItemService.listarPorSeqItemCategoriaCaracteristicaItem(this.constante.seqItemCategoriaCaracteristaItemTotem)

        this.setState({
            /* TELA */
            tela: this.TELAS.TIPO_SERVICO,
            telaRetorno: null,

            /* OBJETO */
            tipoServico: null,
            formaPagamento: null,
            fazerPopAoVoltar: false,

            /* OBJETO LIRIDA */
            pessoa: new Pessoa(),
            pessoaFabricante: new Pessoa(),
            endereco: new Endereco(),
            documento: new Documento(),
            documentoItem: new DocumentoItem(),
            tabelaPreco: new TabelaPreco(),
            itemCategoriaCaracteristicaItem: new ItemCategoriaCaracteristicaItem(),
            itemRelacionamento: new ItemRelacionamento(),

            /* LISTA */
            listaProduto: [],
            listaCategoria: [],
            listaItemCategoriaCaracteristicaItem: this.retornoItemCategoriaCaracteristicaItem.objeto,
            listaTabelaPreco: [],
            listaPessoaFabricante: [],

            /* MODAL */
            vModalAddItem: false,
        })
    }

    // alerta_deletarItem(pItem) {
    //     Alert.alert(
    //         "Quer deletar este item?",
    //         pItem.itemNome,
    //         [{ text: 'sim', onPress: () => this.deletarItem(pItem) }, { text: 'não' }]
    //     )
    // }

    alerta_adicionarItem() {
        this.retornoAlerta = window.confirm('Gostaria de adicionar mais um item?');

        if (this.retornoAlerta === false) {
            this.setState({ tela: this.TELAS.DADOS_PESSOAIS });
            return;
        }

        this.adicionarItem();
    }

    formatarPessoaTelefone(pTexto) {
        this.setState({
            pessoa: {
                ...this.state.pessoa,
                telefonePlataformaLirida: this.util.formatarTelefone(pTexto)
            }
        })
    }

    formatarPessoaDocumento(pTexto) {
        this.setState({
            pessoa: {
                ...this.state.pessoa,
                documento: this.util.formatarCPFCNPJ(pTexto)
            }
        })
    }

    async salvarDadosPessoais() {

        this.validacaoDocumento = await this.util.validarDocumento(this.state.pessoa.documento);

        if (this.validacaoDocumento === false) {
            window.alert('Documento inválido');
            return;
        }

        this.setState({
            tela: this.TELAS.DADOS_ENDERECO,
        })
    }

    salvarDadosEndereco() {
        this.setState({
            tela: this.TELAS.DATA_AGENDAMENTO,
        })
    }

    salvarDataAgendamento() {
        this.setState({
            tela: this.TELAS.PAGAMENTO,
        })
    }

    salvarFormaPagamento(pSeqFormaPagamento) {
        this.setState({
            documento: {
                ...this.state.documento,
                seqFormaPagamento: pSeqFormaPagamento
            }
        })
    }

    async finalizar() {
        this.pessoa = this.state.pessoa;
        this.documento = this.state.documento;
        this.listaDocumentoItem = this.state.listaDocumentoItem;
        this.endereco = this.state.endereco;
        this.documentoItemCaracteristicaDocumentoItem = new DocumentoItemCaracteristicaDocumentoItem();
        this.documentoItemRelacionamento = new DocumentoItemRelacionamento();

        this.pessoa.seqPessoaPerfil = this.constante.seqPessoaPerfilCliente;
        this.pessoa.latitude = this.endereco.latitude; // UNDEFINED
        this.pessoa.longitude = this.endereco.longitude; // UNDEFINED
        this.pessoa.placeId = this.endereco.place_id;
        this.pessoa.seqUsuario = this.constante.seqUsuario;

        this.retornoPessoa = await this.pessoaService.salvar(this.pessoa);
        this.pessoa = this.retornoPessoa.objeto;

        console.log(this.pessoa);

        this.endereco.seqPessoa = this.pessoa.seqPessoa;
        this.endereco.seqUsuario = this.constante.seqUsuario;
        this.endereco.seqTipoEndereco = this.constante.seqTipoEnderecoEntrega;

        this.enderecoTexto = this.endereco.logradouro + ', ' +
            this.endereco.numero + ', ' +
            this.endereco.bairro + ', ' +
            this.endereco.cidade + ', ' +
            this.endereco.estado + ', ' +
            this.endereco.uf

        console.log(this.enderecoTexto)
        this.retornoListaEndereco = await this.googleApi.listarEndereco(this.enderecoTexto);
        console.log(this.retornoListaEndereco)

        if (!this.retornoListaEndereco.predictions[0].place_id) {
            alert('Endereço inválido!');
            return;
        }
        console.log('listar endereco OK')

        this.retornoBuscaEndereco = await this.googleApi.buscarEndereco(this.retornoListaEndereco.predictions[0].place_id);
        console.log(this.retornoBuscaEndereco)

        this.endereco.place_id = this.retornoListaEndereco.predictions[0].place_id;
        this.endereco.latitude = this.retornoBuscaEndereco.result.geometry.location.lat;
        this.endereco.longitude = this.retornoBuscaEndereco.result.geometry.location.lng;

        this.retornoEndereco = await this.pessoaService.salvarEndereco(this.endereco);
        this.endereco = this.retornoEndereco.objeto;
        console.log('salvarEndereco OK')

        this.documento.seqUsuario = this.constante.seqUsuario;
        this.documento.seqUsuarioVendedor = this.constante.seqUsuario;
        this.documento.seqTipoDocumento = this.constante.seqTipoDocumento;
        this.documento.seqPessoa = this.pessoa.seqPessoa;
        this.documento.seqEndereco = this.endereco.seqEndereco;
        this.documento.seqTipoDocumentoProcesso = this.constante.seqTipoDocumentoProcessoAguardandoPagamento;
        this.documento.status = this.constante.statusServicoNaoPago;
        this.documento.nfGerada = 'N';

        this.retornoDocumento = await this.documentoService.salvar(this.documento);
        this.documento = this.retornoDocumento.objeto;
        console.log('DOCUMENTO salvar OK')

        // RELACIONAMENTO ITEM CLIENTE
        this.documentoRelacionamento = new DocumentoRelacionamento();
        this.documentoRelacionamento.seqDocumento = this.documento.seqDocumento;
        this.documentoRelacionamento.seqPessoaRelacionada = this.pessoa.seqPessoa;
        this.documentoRelacionamento.seqTipoRelacionamento = this.constante.seqTipoRelacionamentoCliente;
        await this.documentoService.salvarRelacionamento(this.documentoRelacionamento);
        console.log('DOCUMENTO salvar OK')

        // RELACIONAMENTO  SOLICITANTE
        this.documentoRelacionamento = new DocumentoRelacionamento();
        this.documentoRelacionamento.seqDocumento = this.documento.seqDocumento;
        this.documentoRelacionamento.seqPessoaRelacionada = this.state.pessoaUsuario.seqPessoa;
        this.documentoRelacionamento.seqTipoRelacionamento = this.constante.seqTipoRelacionamentoSolicitante;
        await this.documentoService.salvarRelacionamento(this.documentoRelacionamento);

        console.log('DOCUMENTO salvar OK')
        this.listaDocumentoItemCaracteristicaDocumentoItem = [];

        this.documentoTotal = 0;

        for (let i = 0; i < this.listaDocumentoItem.length; i++) {
            this.listaDocumentoItem[i].seqDocumento = this.documento.seqDocumento;
            this.listaDocumentoItem[i].seqUsuario = this.constante.seqUsuario;
            this.listaDocumentoItem[i].seqTipoDocumentoProcesso = this.constante.seqTipoDocumentoProcessoAguardandoPagamento;
            this.listaDocumentoItem[i].status = this.constante.statusServicoNaoPago;
            this.listaDocumentoItem[i].nfGerada = 'N';
            this.listaDocumentoItem[i].dataProgramada = this.documento.dataProgramada;
            this.listaDocumentoItem[i].valorTotal = Number(this.listaDocumentoItem[i].quantidade) * Number(this.listaDocumentoItem[i].precoTabelaMaximo);
            this.listaDocumentoItem[i].seqTipoDocumentoProcesso = this.constante.seqTipoDocumentoProcessoAguardandoPagamento;
            this.documentoTotal = this.documentoTotal + (Number(this.listaDocumentoItem[i].quantidade) * Number(this.listaDocumentoItem[i].precoTabelaMaximo))
            this.retornoDocumentoItem = await this.documentoService.salvarItem(this.listaDocumentoItem[i]);
            this.documentoItem = this.retornoDocumentoItem.objeto;

            // CARACTERISTICA ITEM FABRICANTE
            this.documentoItemCaracteristicaDocumentoItem = new DocumentoItemCaracteristicaDocumentoItem();
            this.documentoItemCaracteristicaDocumentoItem.marcado = 'true';
            this.documentoItemCaracteristicaDocumentoItem.seqDocumentoItemCaracteristica = this.listaDocumentoItem[i].tagn0;
            this.documentoItemCaracteristicaDocumentoItem.seqDocumentoItem = this.documentoItem.seqDocumentoItem;
            this.listaDocumentoItemCaracteristicaDocumentoItem.push(this.documentoItemCaracteristicaDocumentoItem);

            // RELACIONAMENTO ITEM FABRICANTE
            this.documentoItemRelacionamento = new DocumentoItemRelacionamento();
            this.documentoItemRelacionamento.seqDocumentoItem = this.documentoItem.seqDocumentoItem;
            this.documentoItemRelacionamento.seqPessoaRelacionada = this.listaDocumentoItem[i].tagn1;
            this.documentoItemRelacionamento.seqTipoRelacionamento = this.constante.seqTipoRelacionamentoFabricante;
            await this.documentoService.salvarRelacionamentoItem(this.documentoItemRelacionamento);

            // RELACIONAMENTO ITEM CLIENTE
            this.documentoItemRelacionamento = new DocumentoItemRelacionamento();
            this.documentoItemRelacionamento.seqDocumentoItem = this.documentoItem.seqDocumentoItem;
            this.documentoItemRelacionamento.seqPessoaRelacionada = this.pessoa.seqPessoa;
            this.documentoItemRelacionamento.seqTipoRelacionamento = this.constante.seqTipoRelacionamentoCliente;
            await this.documentoService.salvarRelacionamentoItem(this.documentoItemRelacionamento);

            // RELACIONAMENTO ITEM SOLICITANTE
            this.documentoItemRelacionamento = new DocumentoItemRelacionamento();
            this.documentoItemRelacionamento.seqDocumentoItem = this.documentoItem.seqDocumentoItem;
            this.documentoItemRelacionamento.seqPessoaRelacionada = this.state.pessoaUsuario.seqPessoa;
            this.documentoItemRelacionamento.seqTipoRelacionamento = this.constante.seqTipoRelacionamentoSolicitante;
            await this.documentoService.salvarRelacionamentoItem(this.documentoItemRelacionamento);
        }

        await this.documentoService.salvarCaracteristicaDocumentoItem(this.listaDocumentoItemCaracteristicaDocumentoItem);

        this.documento.valorTotal = this.documentoTotal;
        this.retornoDocumento = await this.documentoService.salvar(this.documento);
        this.documento = this.retornoDocumento.objeto;

        /* GERANDO FINANCEIRO */
        this.financeiro = new Financeiro();

        this.financeiro.seqUsuario = this.documento.seqUsuario;
        this.financeiro.seqPessoa = this.documento.seqPessoa;
        this.financeiro.seqContaBancaria = this.constante.seqContaBancaria;
        this.financeiro.seqContaFinanceira = this.constante.seqContaFinanceiraReceber;
        this.financeiro.valorProgramado = this.documento.valorTotal;
        this.financeiro.dataProgramada = new Date();
        this.financeiro.documento = this.state.pessoaUsuario.documento;
        this.financeiro.tipoDocumento = this.state.pessoaUsuario.tipoDocumento;
        this.financeiro.nome = this.documento.vendedorNome;
        this.financeiro.seqTipoDocumento = this.documento.seqTipoDocumento;
        this.financeiro.qtdeBaixa = 0;
        this.financeiro.seqFormaPagamento = this.constante.seqFormaPagamentoPix;
        this.financeiro.seqDocumento = this.documento.seqDocumento;

        this.retornoFinanceiro = await this.financeiroService.salvar(this.financeiro);
        console.log(this.retornoFinanceiro);

        this.enviarMensagemWhatsApp(this.pessoa, this.pessoa.telefonePlataformaLirida);
        this.whatsappService.enviarLinkPagamento(this.pessoa.telefonePlataformaLirida, this.retornoFinanceiro.objeto.chave);

        this.encerrar();
    }

    alerta_encerrar() {
        this.retornoAlerta = window.confirm('Deseja realmente encerrar todo o processo?');

        if (this.retornoAlerta === false) {
            return;
        }

        this.encerrar();
    }

    encerrar() {
        this.setState({
            tela: this.TELAS.FINALIZADO
        })

        setTimeout(
            () => this.reiniciarAplicacao(),
            5000,
        )
    }

    async enviarMensagemWhatsApp(pPessoa, pContato) {
        this.contato = pContato;
        this.pessoa = pPessoa;

        let json = JSON.stringify({ phone: '55' + this.contato.replace('(', '').replace(')', '').replace('-', ''), name: this.pessoa.nome });

        let cabecalho = new Headers();
        cabecalho.append("api-key", "es9FvneS2jxb5hI7i2ieUO5H")
        cabecalho.append("sender", "iex brasil");
        cabecalho.append("Content-Type", "application/json");

        this.retorno = await fetch("https://api.nexflow.app/service/cb72cf27-c082-432d-925e-323035368dfd", {
            method: 'POST',
            headers: cabecalho,
            redirect: 'follow',
            body: json
        })

        this.retorno = this.retorno.json();
        return this.retorno;
    }

    retornaValorTotal() {
        this.listaDocumentoItem = this.state.listaDocumentoItem;
        this.valorTotal = 0;

        for (let i = 0; i < this.listaDocumentoItem.length; i++) {
            this.valorTotal = this.valorTotal + Number(Number(this.listaDocumentoItem[i].quantidade) * Number(this.listaDocumentoItem[i].precoTabelaMaximo))
        }

        return this.valorTotal;
    }

    render() {
        return this.state.pessoaUsuario.seqPessoa && <div className='sis-dr-container'>
            <Header
                pessoaUsuario={this.state.pessoaUsuario}
                selecionado={this.constante.Telas.DigitacaoRapida} />

            <main className='sis-dr-main'>
                <div className='sis-dr-header'>

                    {(this.state.tela !== this.TELAS.FINALIZADO) && (this.state.tela !== this.TELAS.TIPO_SERVICO) &&
                        <button
                            onClick={() => this.alerta_encerrar()}
                            className='sis-dr-header__botao'
                        >
                            <MdClose />
                        </button>
                    }
                </div>

                <div className='sis-dr-conteudo'>
                    {this.state.tela === this.TELAS.INICIO &&
                        <div className='sis-dr-conteudo__iniciar-container'>
                            <button
                                className='sis-dr-conteudo__iniciar'
                                onClick={() => this.iniciar()}
                            >Iniciar</button>
                        </div>}

                    {this.state.tela === this.TELAS.TIPO_SERVICO &&
                        <TipoServico
                            TIPOS_SERVICO={this.TIPOS_SERVICO}
                            setState={pState => this.setState(pState)}
                            tipoServico={this.state.tipoServico}
                        />
                    }

                    {this.state.tela === this.TELAS.ESCOLHA_PRODUTO &&
                        <EscolhaProduto
                            urlBaseArquivo={this.state.urlBaseArquivo}
                            listaProduto={this.state.listaItemCategoriaCaracteristicaItem}
                            produto={this.state.produto}
                            setState={pState => this.setState(pState)}
                        />
                    }

                    {this.state.tela === this.TELAS.ESCOLHA_CATEGORIA &&
                        <EscolhaCategoria
                            formatarValor={pValor => this.util.formatarValor(pValor)}
                            listaCategoria={this.state.listaTabelaPreco}
                            setState={pState => this.setState(pState)}
                            categoria={this.state.categoria}
                        />
                    }

                    {this.state.tela === this.TELAS.ESCOLHA_FABRICANTE &&
                        <EscolhaFabricante
                            urlBaseArquivo={this.state.urlBaseArquivo}
                            listaPessoaFabricante={this.state.listaPessoaFabricante}
                            setState={pState => this.setState(pState)}
                            pessoaFabricante={this.state.pessoaFabricante}
                        />
                    }

                    {this.state.tela === this.TELAS.RESUMO_COMPRA &&
                        <ResumoCompra
                            formatarValor={pValor => this.util.formatarValor(pValor)}
                            diminuirQuantidade={pItem => this.diminuirQuantidadeDocumentoItem(pItem)}
                            aumentarQuantidade={pItem => this.aumentarQuantidadeDocumentoItem(pItem)}
                            alerta_deletarItem={pItem => this.alerta_deletarItem(pItem)}
                            listaCompra={this.state.listaDocumentoItem}
                        />
                    }

                    {this.state.tela === this.TELAS.DADOS_PESSOAIS &&
                        <DadosPessoais
                            formatarPessoaTelefone={pTexto => this.formatarPessoaTelefone(pTexto)}
                            formatarPessoaDocumento={(pTexto) => this.formatarPessoaDocumento(pTexto)}
                            salvarDadosPessoais={() => this.salvarDadosPessoais()}
                            pessoa={this.state.pessoa}
                            setState={(pState) => this.setState(pState)}
                        />
                    }

                    {this.state.tela === this.TELAS.DADOS_ENDERECO &&
                        <DadosEndereco
                            formatarEnderecoCep={pTexto => this.formatarEnderecoCep(pTexto)}
                            imprimirCupom={() => this.imprimirCupom()}
                            salvarDadosPessoais={() => this.salvarDadosEndereco()}
                            buscarCEP={() => this.buscarCEP()}
                            endereco={this.state.endereco}
                            setState={(pState) => this.setState(pState)}
                        />
                    }

                    {this.state.tela === this.TELAS.DATA_AGENDAMENTO &&
                        <DataAgendamento
                            documento={this.state.documento}
                            setState={pState => this.setState(pState)}
                        />
                    }

                    {this.state.tela === this.TELAS.PAGAMENTO &&
                        <Pagamento
                            retornaValorTotal={() => this.retornaValorTotal()}
                            formatarValor={pValor => this.util.formatarValor(pValor)}
                            formatarDataInput={pData => this.util.formatarDataInput(pData)}
                            documento={this.state.documento}
                            pessoa={this.state.pessoa}
                            endereco={this.state.endereco}
                            produto={this.state.produto}
                            categoria={this.state.categoria}
                            pessoaFabricante={this.state.pessoaFabricante}
                            listaDocumentoItem={this.state.listaDocumentoItem}
                        />
                    }

                    {this.state.tela === this.TELAS.FINALIZADO &&
                        <Finalizado />
                    }

                </div>

                <div className='sis-dr-footer'>
                    {(this.state.tela !== this.TELAS.FINALIZADO) && (this.state.tela !== this.TELAS.TIPO_SERVICO) ?
                        <button
                            onClick={() => this.voltar()}
                            className='sis-dr-footer__voltar'
                        >
                            Voltar
                        </button>
                        : <div />
                    }
                    {(this.state.tela === this.TELAS.TIPO_SERVICO && this.state.tipoServico) ||
                        (this.state.tela === this.TELAS.ESCOLHA_PRODUTO && this.state.produto) ||
                        (this.state.tela === this.TELAS.ESCOLHA_CATEGORIA && this.state.categoria) ||
                        (this.state.tela === this.TELAS.ESCOLHA_FABRICANTE && this.state.pessoaFabricante.seqPessoaRelacionada) ||
                        (this.state.tela === this.TELAS.RESUMO_COMPRA && this.state.listaDocumentoItem.length > 0) ||
                        (this.state.tela === this.TELAS.DADOS_PESSOAIS &&
                            this.state.pessoa.nome &&
                            this.state.pessoa.documento &&
                            this.state.pessoa.telefonePlataformaLirida) ||
                        (this.state.tela === this.TELAS.DADOS_ENDERECO &&
                            this.state.endereco.cep &&
                            this.state.endereco.logradouro &&
                            this.state.endereco.numero &&
                            this.state.endereco.bairro &&
                            this.state.endereco.cidade &&
                            this.state.endereco.uf) ||
                        (this.state.tela === this.TELAS.DATA_AGENDAMENTO && this.state.documento.dataProgramada) ||
                        this.state.tela === this.TELAS.PAGAMENTO ?
                        <button
                            onClick={() => this.proximo()}
                            className='sis-dr-footer__proximo'
                        >
                            Próximo
                        </button>
                        : null}
                </div>
            </main>
        </div >
    }
}