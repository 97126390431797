import { Component } from 'react';
import './style.css';

export default class EscolhaFabricante extends Component {
    render() {
        return <div className='sis-dr-ef-container'>
            <h2 className="sis-dr-ef-titulo">Escolha o fabricante</h2>

            <div className='sis-dr-ef-wrapper'>
                {this.props.listaPessoaFabricante.map(item =>
                    <button
                        onClick={() => this.props.setState({ pessoaFabricante: item })}
                        className={this.props.pessoaFabricante === item ?
                            'sis-dr-ef-wrapper__botao sis-dr-ef-wrapper__selecionado' :
                            'sis-dr-ef-wrapper__botao'
                        }
                    >
                        <img
                            src={this.props.urlBaseArquivo + item.pessoaRelacionadaFoto}
                            alt="Fabricante"
                            className='sis-dr-ef-wrapper__imagem'
                        />
                    </button>)}
            </div>
        </div>
    }
}