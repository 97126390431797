import { Component } from 'react';
import './style.css';

export default class DadosEndereco extends Component {
    render() {
        return <div className='sis-dr-de-container'>
            <h2 className='sis-dr-de-titulo'>Local da instalação</h2>

            <div className='sis-dr-de-wrapper'>
                <div className='sis-dr-de-input-wrapper'>
                    <div className='sis-dr-de-input-bloco'>
                        <label className='sis-dr-de-label'>CEP</label><br />
                        <input
                            type='text'
                            className='sis-dr-de-input'
                            onChange={pTexto => this.props.formatarEnderecoCep(pTexto.target.value)}
                            value={this.props.endereco.cep}
                        />
                    </div>

                    <button
                        className='sis-dr-de-input-wrapper__botao'
                        onClick={() => this.props.buscarCEP()}
                    >
                        Buscar CEP
                    </button>
                </div>

                <div className='sis-dr-de-input-wrapper'>
                    <div className='sis-dr-de-input-bloco'>
                        <label className='sis-dr-de-label'>Logradouro</label><br />
                        <input
                            type='text'
                            className='sis-dr-de-input'
                            onChange={pTexto => this.props.setState({
                                endereco: {
                                    ...this.props.endereco,
                                    logradouro: pTexto.target.value
                                }
                            })}
                            value={this.props.endereco.logradouro}
                        />
                    </div>
                    <div className='sis-dr-de-input-bloco'>
                        <label className='sis-dr-de-label'>Número</label><br />
                        <input
                            type='text'
                            className='sis-dr-de-input'
                            onChange={pTexto => this.props.setState({
                                endereco: {
                                    ...this.props.endereco,
                                    numero: pTexto.target.value
                                }
                            })}
                            value={this.props.endereco.numero}
                        />
                    </div>
                </div>

                <div className='sis-dr-de-input-wrapper'>
                    <div className='sis-dr-de-input-bloco'>
                        <label className='sis-dr-de-label'>Complemento</label><br />
                        <input
                            type='text'
                            className='sis-dr-de-input'
                            onChange={pTexto => this.props.setState({
                                endereco: {
                                    ...this.props.endereco,
                                    complemento: pTexto.target.value
                                }
                            })}
                            value={this.props.endereco.complemento}
                        />
                    </div>
                    <div className='sis-dr-de-input-bloco'>
                        <label className='sis-dr-de-label'>Bairro</label><br />
                        <input
                            type='text'
                            className='sis-dr-de-input'
                            onChange={pTexto => this.props.setState({
                                endereco: {
                                    ...this.props.endereco,
                                    bairro: pTexto.target.value
                                }
                            })}
                            value={this.props.endereco.bairro}
                        />
                    </div>
                </div>

                <div className='sis-dr-de-input-wrapper'>
                    <div className='sis-dr-de-input-bloco'>
                        <label className='sis-dr-de-label'>Cidade</label><br />
                        <input
                            type='text'
                            className='sis-dr-de-input'
                            onChange={pTexto => this.props.setState({
                                endereco: {
                                    ...this.props.endereco,
                                    cidade: pTexto.target.value
                                }
                            })}
                            value={this.props.endereco.cidade}
                        />
                    </div>
                    <div className='sis-dr-de-input-bloco'>
                        <label className='sis-dr-de-label'>UF</label><br />
                        <input
                            type='text'
                            className='sis-dr-de-input'
                            onChange={pTexto => this.props.setState({
                                endereco: {
                                    ...this.props.endereco,
                                    uf: pTexto.target.value
                                }
                            })}
                            value={this.props.endereco.uf}
                        />
                    </div>
                </div>

                <div className='sis-dr-de-input-wrapper'>
                    <div className='sis-dr-de-input-bloco'>
                        <label className='sis-dr-de-label'>Ponto de referência</label><br />
                        <input
                            type='text'
                            className='sis-dr-de-input'
                            onChange={pTexto => this.props.setState({
                                endereco: {
                                    ...this.props.endereco,
                                    pontoReferencia: pTexto.target.value
                                }
                            })}
                            value={this.props.endereco.pontoReferencia}
                        />
                    </div>
                </div>


            </div>
        </div>
    }
}