import { Component, createRef } from 'react';
import PessoaService from 'lirida-back-service/Servicos/Pessoa/PessoaService';
import Constante from '../../../../util/Constante';
import Util from '../../../../util/Util';
import DocumentoService from 'lirida-back-service/Servicos/Documento/DocumentoService';

export default class Mapa extends Component {
    pessoaService = new PessoaService();
    documentoService = new DocumentoService();
    util = new Util();
    constante = new Constante();

    mapaPessoaRef = createRef();
    mapaOSRef = createRef();

    coordenadasBrasil = {
        lat: -14.2401,
        lng: -53.1805,
    }

    async componentDidMount() {
        const map = new window.google.maps.Map(this.mapaPessoaRef.current, {
            center: this.coordenadasBrasil,
            zoom: 4,
        });

        this.iniciar(map);
    }

    async iniciar(pMap) {
        const map = pMap;

        this.listaPessoa = await this.listar();

        for (let i = 0; i < this.listaPessoa.length; i++) {
            const contentString =
                "</div>" +
                '<h2>' + this.listaPessoa[i].nome + '</h2>' +
                '<h3>' + this.listaPessoa[i].emailPlataformaLirida + '</h3>' +
                "</div>";

            const infowindow = new window.google.maps.InfoWindow({
                content: contentString,
                maxWidth: 200,
            });

            const marker = new window.google.maps.Marker({
                position: {
                    lat: Number(this.listaPessoa[i].latitude),
                    lng: Number(this.listaPessoa[i].longitude),
                },
                map,
                icon: {
                    url: require('../../../../assets/logo-icone.png'),
                    scaledSize: {
                        width: 20,
                        height: 20,
                    },
                    size: {
                        width: 50,
                        height: 50,
                    },
                }
                // title: this.listaPessoa[i].nome,
            })

            marker.addListener("click", () => {
                infowindow.open({
                    anchor: marker,
                    map,
                    shouldFocus: false,
                });
            });
        }

        setTimeout(() => this.iniciar(), 60000)
    }

    async listar() {
        this.retornoListaPessoa = await this.pessoaService.listar(
            this.constante.seqUsuario,
            '',
            this.constante.seqPessoaPerfilTecnico,
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '')

        this.listaPessoa = this.retornoListaPessoa.objeto;
        return this.listaPessoa;
    }

    render() {
        return <div ref={this.mapaPessoaRef} style={{ width: '100%', height: '100vh' }}></div>
    }
}