import Pessoa from 'lirida-back-service/Servicos/Pessoa/Pessoa';
import { Component } from 'react';
import { MdArrowBack } from 'react-icons/md';
import { HiTrash } from 'react-icons/hi';
import './style.css';
import Loading from '../../../../../../components/Loading';

export default class PessoaPg extends Component {
    render() {
        return <div className='sis-pes-pe-container'>
            <div className='sis-pes-pe-header'>
                <button
                    onClick={() => this.props.setState({ pessoa: new Pessoa(), pessoaBase: new Pessoa(), novaPessoa: false, })}
                    className='sis-pes-pe-header__botao'
                >
                    <MdArrowBack />
                </button>
                <h2 className='sis-pes-pe-header__titulo'>Usuario</h2>
            </div>

            {this.props.carregando ? <Loading /> : <>
                <form
                    onSubmit={(pEvent) => this.props.salvar(pEvent.preventDefault())}
                    className='sis-pes-pe-wrapper'>
                    <div className='sis-pes-pe-wrapper__div'>
                        <div><label className='sis-pes-pe-wrapper__titulo'>Nome</label></div>
                        <input
                            type="text"
                            required
                            className='sis-pes-pe__input'
                            value={this.props.pessoa.nome}
                            onChange={pTexto => this.props.setState({
                                pessoa: {
                                    ...this.props.pessoa,
                                    nome: pTexto.target.value
                                }
                            })} />
                    </div>
                    <div className='sis-pes-pe-wrapper__div'>
                        <div><label className='sis-pes-pe-wrapper__titulo'>CPF/CNPJ</label></div>
                        {this.props.novaPessoa ? <input
                            type="text"
                            required
                            value={this.props.pessoa.documento}
                            onChange={(pTexto) => this.props.formatarDocumento(pTexto.target.value)} />
                            :
                            <p className='sis-pes-pe-wrapper__descricao'>
                                {this.props.pessoa.documento}
                            </p>
                        }
                    </div>
                    {this.props.novaPessoa ?
                        null
                        :
                        <div className='sis-pes-pe-wrapper__div'>
                            <div><label className='sis-pes-pe-wrapper__titulo'>Perfil</label></div>
                            <p className='sis-pes-pe-wrapper__descricao'>
                                {this.props.pessoa.perfilNome}
                            </p>
                        </div>
                    }

                    <div className='sis-pes-pe-wrapper__div'>
                        <div><label className='sis-pes-pe-wrapper__titulo'>Email</label></div>
                        {this.props.novaPessoa ?
                            <input
                                type='email'
                                required
                                onChange={(pTexto) => this.props.setState({ pessoa: { ...this.props.pessoa, emailPlataformaLirida: pTexto.target.value } })} />
                            :
                            <p className='sis-pes-pe-wrapper__descricao'>
                                {this.props.pessoa.emailPlataformaLirida}
                            </p>}
                    </div>
                    <div className='sis-pes-pe-wrapper__div'>
                        <div><label className='sis-pes-pe-wrapper__titulo'>Situação</label></div>
                        <select
                            onChange={pValor => this.setState({
                                pessoa: {
                                    ...this.props.pessoa,
                                    situacao: pValor.target.value
                                }
                            })}
                            value={this.props.pessoa.situacao}
                        >
                            <option value="ATIVO">ATIVO</option>
                            <option value="INATIVO">INATIVO</option>
                        </select>
                    </div>

                    <div>
                        <button
                            className='sis-pes-pe-wrapper__botao'
                            type='submit'>Salvar</button>
                    </div>
                </form>

                {this.props.novaPessoa ? null :
                    <div
                        className='sis-pes-pe-wrapper-container_checkBox'
                    >
                        <h3>Liberar acesso a tela</h3>
                        <input onChange={() => this.props.marcarDesmarcar(0)} checked={this.props.pessoa.tags0 === 'S' ? true : false} type='checkbox' />
                        <label className='sis-pes-pe-wrapper-cointaienr_labelCheckBox'>Ordem de serviço</label>

                        <input onChange={() => this.props.marcarDesmarcar(1)} checked={this.props.pessoa.tags1 === 'S' ? true : false} type='checkbox' />
                        <label className='sis-pes-pe-wrapper-cointaienr_labelCheckBox' >Digitação rápida</label>

                        <input onChange={() => this.props.marcarDesmarcar(2)} checked={this.props.pessoa.tags2 === 'S' ? true : false} type='checkbox' />
                        <label className='sis-pes-pe-wrapper-cointaienr_labelCheckBox' >Financeiro</label>

                        <input onChange={() => this.props.marcarDesmarcar(3)} checked={this.props.pessoa.tags3 === 'S' ? true : false} type='checkbox' />
                        <label className='sis-pes-pe-wrapper-cointaienr_labelCheckBox' >Mapa documento</label>

                        <input onChange={() => this.props.marcarDesmarcar(4)} checked={this.props.pessoa.tags4 === 'S' ? true : false} type='checkbox' />
                        <label className='sis-pes-pe-wrapper-cointaienr_labelCheckBox'>Cadastro</label>

                        <input onChange={() => this.props.marcarDesmarcar(5)} checked={this.props.pessoa.tags5 === 'S' ? true : false} type='checkbox' />
                        <label className='sis-pes-pe-wrapper-cointaienr_labelCheckBox'>Iexcombo</label>
                    </div>
                }


                {this.props.pessoa.seqPessoa && <>
                    <div
                        className='sis-pes-pe-wrapper_selecione'>

                        <div>
                            <label>Selecione</label><br />
                            <select
                                onChange={(pTexto) => this.props.buscarPessoaBase(pTexto.target.value)}
                                value={this.props.pessoaBase.seqPessoa || ''}
                            >
                                <option value=''>Selecione</option>
                                {this.props.listaPessoaBase.map(pPessoa => <option value={pPessoa.seqPessoa}>{pPessoa.nome}</option>)}
                            </select>
                        </div>

                        <button
                            className='sis-pes-pe-wrapper__botao'
                            onClick={() => this.props.salvarPessoaRelacionamento()}>
                            adicionar
                        </button>
                    </div>


                    <table className="sis-cad-main__tabela">
                        <thead className="sis-cad-main__thead">
                            <tr>
                                <th className="sis-cad-main__th">id</th>
                                <th className="sis-cad-main__th">Nome</th>
                                <th className="sis-cad-main__th">Perfil</th>
                                <th className="sis-cad-main__th">Ação</th>
                            </tr>
                        </thead>

                        <tbody>
                            {this.props.listaPessoaRelacionamento.map(pPessoa =>
                                <tr>
                                    <td className="sis-cad-main__td">{pPessoa.seqPessoaRelacionamento}</td>
                                    <td className="sis-cad-main__td">{pPessoa.pessoaRelacionadaNome}</td>
                                    <td className="sis-cad-main__td">{pPessoa.tipoRelacionamentoNome}</td>
                                    <td className="sis-cad-main__td">
                                        <button
                                            className='sis-pes-pe-botao_excluir'
                                            onClick={() => this.props.deletarRelacionamento(pPessoa)}>
                                            <HiTrash />
                                        </button>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </>}</>}
        </div >
    }
}