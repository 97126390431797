import { Component } from 'react';
import './style.css';

export default class DataAgendamento extends Component {
    render() {
        return <div className="sis-dr-da-container">
            <h2 className='sis-dr-da-titulo'>Agendamento</h2>

            <div className='sis-dr-da-wrapper'>
                <div className='sis-dr-da-input-wrapper'>
                    <label className='sis-dr-da-label'>Melhor data para a realização do serviço</label>
                    <input
                        className='sis-dr-da-input'
                        type="date"
                        onChange={pTexto => this.props.setState({
                            documento: {
                                ...this.props.documento,
                                dataProgramada: pTexto.target.value
                            }
                        })}
                        value={this.props.documento.dataProgramada}
                    />
                </div>
            </div>
        </div>
    }
}