import Contato from 'lirida-back-service/Servicos/Contato/Contato';
import Endereco from 'lirida-back-service/Servicos/Endereco/Endereco';
import GoogleAPI from 'lirida-back-service/Servicos/Google/GoogleAPI';
import Pessoa from 'lirida-back-service/Servicos/Pessoa/Pessoa';
import PessoaService from 'lirida-back-service/Servicos/Pessoa/PessoaService';
import PessoaArquivo from 'lirida-back-service/Servicos/PessoaArquivo/PessoaArquivo';
import PessoaAtendimento from 'lirida-back-service/Servicos/PessoaAtendimento/PessoaAtendimento';
import PessoaCaracteristicaPessoaService from 'lirida-back-service/Servicos/PessoaCaracteristicaPessoa/PessoaCaracteristicaPessoaService';
import PessoaPerfil from 'lirida-back-service/Servicos/PessoaPerfil/PessoaPerfil';
import PessoaPerfilService from 'lirida-back-service/Servicos/PessoaPerfil/PessoaPerfilService';
import PessoaRelacionamento from 'lirida-back-service/Servicos/PessoaRelacionamento/PessoaRelacionamento';
import TipoArquivo from 'lirida-back-service/Servicos/TipoArquivo/TipoArquivo';
import TipoArquivoService from 'lirida-back-service/Servicos/TipoArquivo/TipoArquivoService';
import TipoAtendimento from 'lirida-back-service/Servicos/TipoAtendimento/TipoAtendimento';
import TipoAtendimentoService from 'lirida-back-service/Servicos/TipoAtendimento/TipoAtendimentoService';
import TipoContato from 'lirida-back-service/Servicos/TipoContato/TipoContato';
import TipoContatoService from 'lirida-back-service/Servicos/TipoContato/TipoContatoService';
import TipoEndereco from 'lirida-back-service/Servicos/TipoEndereco/TipoEndereco';
import TipoEnderecoService from 'lirida-back-service/Servicos/TipoEndereco/TipoEnderecoService';
import TipoRelacionamento from 'lirida-back-service/Servicos/TipoRelacionamento/TipoRelacionamento';
import TipoRelacionamentoService from 'lirida-back-service/Servicos/TipoRelacionamento/TipoRelacionamentoService';
import { Component } from 'react';
import { HiEye } from 'react-icons/hi';
import Loading from '../../../../components/Loading';
import Constante from '../../../../util/Constante';
import Util from '../../../../util/Util';
import Header from '../Header';
import PessoaPg from './components/PessoaPg';
import './style.css';

export default class Pessoas extends Component {
    util = new Util();
    constante = new Constante();
    pessoaService = new PessoaService(this.constante.token, this.constante.url_base);
    tipoContatoService = new TipoContatoService(this.constante.token, this.constante.url_base);
    tipoEnderecoService = new TipoEnderecoService(this.constante.token, this.constante.url_base);
    pessoaCaracteristicaPessoaService = new PessoaCaracteristicaPessoaService(this.constante.token, this.constante.url_base);
    tipoRelacionamentoService = new TipoRelacionamentoService(this.constante.token, this.constante.url_base);
    tipoArquivoService = new TipoArquivoService(this.constante.token, this.constante.url_base);
    tipoAtendimentoService = new TipoAtendimentoService(this.constante.token, this.constante.url_base);
    pessoaPerfilService = new PessoaPerfilService(this.constante.token, this.constante.url_base);
    googleAPI = new GoogleAPI(this.constante.token, this.constante.url_base);

    state = {
        processoCarregando: false,
        listaCarregando: false,
        pessoaUsuario: new Pessoa(),
        pessoa: new Pessoa(),
        filtro: 'com filtro',
        listaPessoa: [],
        tela: '1',
        conteudo: '',
        popupDeletarPessoa: false,
        msgOKVisivel: false,
        msgErrorVisivel: false,
        mensagem: '',
        carregando: false,
        tag: 'Enderecos',
        contato: new Contato(),
        listaTipoContato: [],
        listaContato: [],
        tipoContato: new TipoContato(),
        popupDeletarContatoVisivel: false,
        endereco: new Endereco(),
        listaTipoEndereco: [],
        listaEndereco: [],
        tipoEndereco: new TipoEndereco(),
        listaPessoaCaracteristica: [],
        listaPessoaPerfil: [],
        listaPessoaAtendimento: [],
        listaTipoAtendimento: [],
        pessoaAtendimento: new PessoaAtendimento(),
        tipoAtendimento: new TipoAtendimento(),
        popupDeletarEnderecoVisivel: false,
        listaTipoArquivo: [],
        listaPessoaArquivo: [],
        pessoaArquivo: new PessoaArquivo(),
        tipoArquivo: new TipoArquivo(),
        popupDeletarPessoaArquivoVisivel: false,
        pessoaPerfil: new PessoaPerfil(),
        listaPessoaRelacionamento: [],
        pessoaRelacionamento: new PessoaRelacionamento(),
        tipoRelacionamento: new TipoRelacionamento(),
        listaTipoRelacionamento: [],
        listaPessoaDoRelacionamento: [],
        popupDeletarPessoaRelacionamentoVisivel: false,
        listaEnderecoGoogle: []
    }

    async componentDidMount() {
        await this.iniciar();
    }

    async iniciar() {
        let pessoaUsuario = await this.util.buscarLocalStorage('PESSOA_USUARIO');
        this.urlBase = await this.util.buscarUrlBaseArquivo();

        this.setState({
            pessoaUsuario: pessoaUsuario,
            urlBase: this.urlBase,
        });

        this.listar(null);
        this.listarPessoaPerfil();
    }

    async listar(pPessoaPerfil) {
        this.setState({ listaCarregando: true });
        this.pessoaPerfil = pPessoaPerfil;

        if (this.pessoaPerfil === null) {
            this.pessoaPerfil = {
                seqPessoaPerfil: '',
            };
        }

        this.setState({
            pessoaPerfil: this.pessoaPerfil,
            listaPessoa: [],
        });

        let retorno = await this.pessoaService.listar(
            this.constante.seqUsuario,
            this.state.conteudo,
            this.pessoaPerfil.seqPessoaPerfil,
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '')
        this.setState({
            listaCarregando: false,
            listaPessoa: retorno.objeto,
        })
    }

    async listarPessoaPerfil() {
        this.setState({ processoCarregando: true, })
        let retorno = await this.pessoaPerfilService.listar(this.constante.seqUsuario)
        this.setState({
            listaPessoaPerfil: retorno.objeto,
            processoCarregando: false,
        });
    }

    selecionar(pPessoa) {
        this.setState({
            pessoa: pPessoa,
        })
    }

    render() {
        return <div className='sis-pes-container'>
            <Header
                pessoaUsuario={this.state.pessoaUsuario}
                selecionado="sis-pes"
            />

            {this.state.pessoa.seqPessoa &&
                <PessoaPg
                    setState={pState => this.setState(pState)}
                    pessoa={this.state.pessoa}
                />
            }

            {!this.state.pessoa.seqPessoa &&
                <main className='sis-pes-main'>
                    <div className='sis-pes-div1'>
                        <div className='sis-pes-div1__wrapper'>

                            {this.state.processoCarregando && <Loading />}

                            {!this.state.processoCarregando &&
                                <button
                                    onClick={() => this.listar(null)}
                                    className={
                                        this.state.pessoaPerfil.seqPessoaPerfil === '' ?
                                            "sis-pes-div1__perfil sis-pes-div1__selecionado" :
                                            "sis-pes-div1__perfil"
                                    }>Todos</button>}
                            {this.state.listaPessoaPerfil.map(pPessoaPerfil =>
                                <button
                                    onClick={() => this.listar(pPessoaPerfil)}
                                    className={
                                        this.state.pessoaPerfil.seqPessoaPerfil ===
                                            pPessoaPerfil.seqPessoaPerfil ?
                                            'sis-pes-div1__perfil sis-pes-div1__selecionado' :
                                            'sis-pes-div1__perfil'
                                    }>
                                    {pPessoaPerfil.nome}
                                </button>
                            )}
                        </div>
                    </div>

                    <div className='sis-pes-div2'>
                        <div className='sis-pes-div2__wrapper'>
                            <h2 className='sis-pes-div2__titulo'>Pessoas</h2>

                            <div>
                                <form
                                    className='sis-pes-form'
                                    onSubmit={pSubmit =>
                                        this.listar(this.state.pessoaPerfil, pSubmit.preventDefault())}
                                >
                                    <input
                                        className='sis-pes-form__input'
                                        placeholder='pesquisar'
                                        onChange={pTexto =>
                                            this.setState({ conteudo: pTexto.target.value })
                                        }
                                        value={this.state.conteudo}
                                    />
                                    <button
                                        className='sis-pes-form__botao'
                                        type="submit"
                                    >
                                        Buscar
                                    </button>
                                </form>

                            </div>

                            <table className='sis-pes-div2__tabela'>
                                <thead>
                                    <tr className='sis-pes-div2__thead'>
                                        <th className='sis-pes-div2__th'>id</th>
                                        <th className='sis-pes-div2__th'>Nome</th>
                                        <th className='sis-pes-div2__th'>Perfil</th>
                                        <th className='sis-pes-div2__th'>Ação</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {this.state.listaPessoa.map(pPessoa =>
                                        <tr>
                                            <td className='sis-pes-div2__td'>{pPessoa.seqPessoa}</td>
                                            <td className='sis-pes-div2__td'>{pPessoa.nome}</td>
                                            <td className='sis-pes-div2__td'>{pPessoa.perfilNome}</td>
                                            <td className='sis-pes-div2__td'>
                                                <button className='sis-pes-div2__botao'
                                                    onClick={
                                                        () => this.selecionar(pPessoa)
                                                    }>
                                                    <HiEye />
                                                </button>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>

                            {this.state.listaCarregando && <Loading />}

                            {!this.state.listaCarregando && this.state.listaPessoa.length === 0 ?
                                <p>Nenhuma pessoa encontrada</p>
                                : null}
                        </div>
                    </div>
                </main >
            }
        </div >
    }
}