import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";
import PessoaCaracteristicaPessoaService from "lirida-back-service/Servicos/PessoaCaracteristicaPessoa/PessoaCaracteristicaPessoaService";
import PessoaRelacionamento from "lirida-back-service/Servicos/PessoaRelacionamento/PessoaRelacionamento";
import Endereco from "lirida-back-service/Servicos/Endereco/Endereco";
import { Component } from "react"
import { render } from "react-dom"
import { HiEye } from "react-icons/hi";
import Constante from "../../../../util/Constante"
import Util from "../../../../util/Util";
import Header from "../Header"
import PessoaIexcombo from "./components/PessoaIexcombo";
import './style.css';

export default class Iexcombo extends Component {
    constante = new Constante();
    util = new Util();
    pessoaService = new PessoaService(this.constante.token, this.constante.url_base);
    pessoaCaracteristicaPessoaService = new PessoaCaracteristicaPessoaService();

    state = {
        pessoaUsuario: new Pessoa(),
        listaPessoa: [],
        listaPessoaRelacionada: [],
        conteudo: '',
        pessoa: new Pessoa(),
        pessoaRelacionada: new Pessoa(),
        enderecoPessoa: new Endereco(),
    }

    componentDidMount() {
        this.iniciar();
    }

    async iniciar() {
        this.pessoaUsuario = await this.util.buscarLocalStorage('PESSOA_USUARIO');

        // await this.listar();

        console.log(this.retornoListaPessoa);

        this.setState({
            pessoaUsuario: this.pessoaUsuario,
        });
    }

    async listar() {
        this.retornoListaPessoa = await this.pessoaService.listar(
            this.constante.seqUsuario,
            this.state.conteudo,
            this.constante.seqPessoaPerfilTecnico,
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            ''
        );

        this.setState({
            listaPessoa: this.retornoListaPessoa.objeto,
        })
    }

    async listarRelacionamento(pPessoa) {
        this.retornoListaRelacionamento = await this.pessoaService.listarRelacionamento(pPessoa);
        this.setState({ listaPessoaRelacionada: this.retornoListaRelacionamento.objeto });
    }

    async deletarRelacionamento(pPessoaRelacionamento) {

        this.retorno = window.confirm(`Deseja deletar ${pPessoaRelacionamento.pessoaRelacionadaNome}`)

        if (!this.retorno) {
            return;
        }

        await this.pessoaService.deletarRelacionamento(pPessoaRelacionamento);
        this.listarRelacionamento(this.state.pessoa);
        this.setState({
            pessoaRelacionada: new PessoaRelacionamento(),
        })
    }

    async salvarRelacionamento() {
        this.pessoaRelacionada = this.state.pessoaRelacionada;

        if (!this.pessoaRelacionada.nome) {
            window.alert('Nome obrigatório');
            return;
        }

        if (!this.pessoaRelacionada.documento) {
            window.alert('Documento obrigatório');
            return;
        }

        if (!this.pessoaRelacionada.dataNascimento) {
            window.alert('Data de nascimento obrigatório');
            return;
        }

        this.pessoaRelacionada.tipoDocumento = 'CPF';
        this.pessoaRelacionada.seqPessoaPerfil = this.constante.seqPessoaPerfilDependenteAIG;
        this.pessoaRelacionada.dataNascimento = String(this.pessoaRelacionada.dataNascimento).split('-').reverse().join('-') + 'T00:00:00';

        if (!this.pessoaRelacionada.seqPessoa) {
            this.retornoPessoaRelacionada = await this.pessoaService.salvar(this.pessoaRelacionada);
            this.pessoaRelacionada = this.retornoPessoaRelacionada.objeto;

            this.pessoaRelacionamento = new PessoaRelacionamento();
            this.pessoaRelacionamento.seqPessoa = this.state.pessoa.seqPessoa;
            this.pessoaRelacionamento.seqPessoaRelacionada = this.pessoaRelacionada.seqPessoa;
            this.pessoaRelacionamento.seqTipoRelacionamento = this.constante.seqTipoRelacionamentoDependenteIexcombo;

            await this.pessoaService.salvarRelacionamento(this.pessoaRelacionamento);
        } else {
            await this.pessoaService.salvar(this.pessoaRelacionada);
        }

        this.listarRelacionamento(this.state.pessoa);

        this.setState({
            pessoaRelacionada: new PessoaRelacionamento(),
        })
    }

    async selecionarPessoa(pPessoa) {
        await this.listarRelacionamento(pPessoa);
        this.retornoListaEndereco = await this.pessoaService.listarEndereco(pPessoa);
        this.listaEndereco = this.retornoListaEndereco.objeto;

        this.enderecoPessoa = new Endereco();

        for (let i = 0; i < this.listaEndereco.length; i++) {
            if (this.listaEndereco[i].seqTipoEndereco === this.constante.seqTipoEnderecoCobranca) {
                this.enderecoPessoa = this.listaEndereco[i];
            }
        }

        this.setState({
            pessoa: pPessoa,
            enderecoPessoa: this.enderecoPessoa
        });
    }

    async selecionarPessoaRelacionamento(pPessoaRelacionamento) {
        this.pessoaRelacionamento = pPessoaRelacionamento;
        this.retornoPessoaRelacionada = await this.pessoaService.buscarPorSeqPessoa(this.pessoaRelacionamento.seqPessoaRelacionada);
        this.setState({ pessoaRelacionada: this.retornoPessoaRelacionada.objeto });
    }

    async gerarFinanceiro() {
        this.pessoa = this.state.pessoa;
        this.pessoa.tags9 = '1';
        this.retornoPessoa = await this.pessoaService.salvar(this.pessoa);
        this.pessoa = this.retornoPessoa;
        this.setState({ pessoa: this.pessoa });
    }

    async loginAIG() {
        this.headers = new Headers();

        this.headers.append('Content-Type', 'application/json');

        this.retornoToken = await fetch('https://app.affinity.do/iex_pruebas/api/login/authenticate', {
            headers: this.headers,
            method: 'POST',
            body: JSON.stringify({
                username: "USERBR",
                password: "User.test2020"
            }),
        });
        this.retornoToken = await this.retornoToken.json();
        console.log(this.retornoToken);
        return this.retornoToken;
    }

    async criarCotacao(pListaPessoaRelacionada, pToken) {
        this.headers = new Headers();
        this.pessoa = this.state.pessoa;

        this.tamanhoLista = 0;

        for (let i = 0; i < pListaPessoaRelacionada.length; i++) {
            if (pListaPessoaRelacionada[i].seqTipoRelacionamento === this.constante.seqTipoRelacionamentoDependenteIexcombo) {
                this.tamanhoLista = this.tamanhoLista + 1;
            }
        }

        this.headers.append('Content-Type', 'application/json');
        this.headers.append("Authorization", "Bearer " + pToken);

        this.body = {
            "product_id": "AH/APN",
            "plan_id": "200",
            "payment_method": "M",
            "cover_type": this.tamanhoLista === 0 ? 'C' : `C${this.tamanhoLista}`,
            "id_type": "CI",
            "id_number": String(this.pessoa.documento).replace('.', '').replace('.', '').replace('-', ''),
            "first_name": this.pessoa.nome,
            "last_name_1": this.pessoa.nome,
            "sex": this.pessoa.sexo,
            "dob": String(this.pessoa.dataNascimento).split('T')[0].split('-').reverse().join('-'),

            "phone_1": "21990140660",
            "phone_2": "21975880221",
            "address_1": this.state.enderecoPessoa.logradouro,
            "address_2": this.state.enderecoPessoa.complemento + ' ',
            "zip_code": this.state.enderecoPessoa.cep,

            "door_number": this.state.enderecoPessoa.numero,
            "Neighborhood": this.state.enderecoPessoa.bairro,
            "city": this.state.enderecoPessoa.cidade,
            "code_level1": "1",
            "civil_status": "2",
            "ocupation": "Tecnico",
            "email": this.pessoa.emailPlataformaLirida,
            "user_id": "1675",
            "user_branch": ""
        }

        // this.body = {
        //     "product_id": "AH/APN",
        //     "plan_id": "200",
        //     "payment_method": "M",
        //     "cover_type": "C3",
        //     "id_type": "CI",
        //     "id_number": "08964992008",
        //     "first_name": this.pessoa.nome,
        //     "last_name_1": this.pessoa.nome,
        //     "sex": "M",
        //     "dob": String(this.pessoa.dataNascimento).split('T')[0].split('-').reverse().join('-'),
        //     "phone_1": "21990140660",
        //     "phone_2": "21975880221",
        //     "address_1": this.state.enderecoPessoa.logradouro,
        //     "address_2": "casa",
        //     "zip_code": this.state.enderecoPessoa.cep,
        //     "door_number": "258",
        //     "Neighborhood": "nova iguaçu",
        //     "city": "Rio de janeiro",
        //     "code_level1": "1",
        //     "civil_status": "2",
        //     "ocupation": "Analista",
        //     "email": "kevinbzrra@gmail.com",
        //     "user_id": "1675",
        //     "user_branch": ""
        // }

        console.log(this.body);

        this.retornoCotacao = await fetch('https://app.affinity.do/iex_pruebas/api/quote/create', {
            headers: this.headers,
            method: 'POST',
            body: JSON.stringify(this.body),
        });
        this.retornoCotacao = await this.retornoCotacao.json();
        return this.retornoCotacao;
    }

    async criarApolice(pRetornoCotacao, pToken) {
        this.headers = new Headers();
        this.headers.append('Content-Type', 'application/json');
        this.headers.append("Authorization", "Bearer " + pToken);

        this.body = {
            "process_id": String(pRetornoCotacao.process_Id),
            "effective_from": new Date().toLocaleDateString().split('/').reverse().join('/'),
            "payment_currency": "214",
            "account_holder_type": "T",
            "account_type": "CCO",
            "account_type_description": "Contas Correntes",
            "account_month": "",
            "account_year": "",
            "account_number": "43424XX33338",
            "user_id": "1675",
            "user_name": "USERBR",
            "user_branch": "DEMO"
        }

        console.log(this.body)

        this.retornoApolice = await fetch('https://app.affinity.do/iex_pruebas/api/policy/create', {
            headers: this.headers,
            method: 'POST',
            body: JSON.stringify(this.body)
        }
        )


        this.retornoApolice = await this.retornoApolice.json();
        return this.retornoApolice;
    }


    async criarPessoa(pRetornoCotacao, pPessoaRelacionada, pPessoaRelacionadaIndex, pToken) {
        this.headers = new Headers();
        this.body = {
            "person_id": "",
            "process_id": String(pRetornoCotacao.process_Id),
            "is_insured": "S",
            "insured_type": String(pPessoaRelacionadaIndex + 2),
            "is_beneficiary": "S",
            "beneficiary_type": "0",
            "id_type": "CI",
            "id_number": String(pPessoaRelacionada.pessoaRelacionadaDocumento).replace('.', '').replace('.', '').replace('-', ''),
            "first_name": pPessoaRelacionada.pessoaRelacionadaNome,
            "last_name_1": pPessoaRelacionada.pessoaRelacionadaNome,
            "sex": pPessoaRelacionada.pessoaRelacionadaSexo,
            "dob": String(pPessoaRelacionada.pessoaRelacionadaDataNascimento).split('T')[0].split('-').reverse().join('/'),
            "benefit_ammount": "2"
        }

        console.log(this.body)

        this.headers.append('Content-Type', 'application/json');
        this.headers.append("Authorization", "Bearer " + pToken);

        this.retornoPessoa = await fetch('https://app.affinity.do/iex_pruebas/api/person/create', {
            headers: this.headers,
            method: 'POST',
            body: JSON.stringify(this.body)
        });
        this.retornoPessoa = await this.retornoPessoa.json();
        return this.retornoPessoa;
    }

    async enviarAIG() {
        this.listaPessoaRelacionada = this.state.listaPessoaRelacionada;
        console.log(this.listaPessoaRelacionada);
        this.pessoa = this.state.pessoa;

        this.retornoToken = await this.loginAIG();

        this.retornoCotacao = await this.criarCotacao(this.listaPessoaRelacionada, this.retornoToken.token);
        console.log(this.retornoCotacao);
        for (let i = 0; i < this.listaPessoaRelacionada.length; i++) {
            if (this.listaPessoaRelacionada[i].seqTipoRelacionamento === this.constante.seqTipoRelacionamentoDependenteIexcombo) {
                this.retornoPessoa = await this.criarPessoa(this.retornoCotacao, this.listaPessoaRelacionada[i], i, this.retornoToken.token);
                console.log(this.retornoPessoa);
            }
        }
        this.retornoApolice = await this.criarApolice(this.retornoCotacao, this.retornoToken.token);
        console.log(this.retornoApolice);

        if (this?.retornoApolice?.policy_Id) {
            this.pessoa.tags8 = this.retornoApolice.policy_Id;
            this.pessoa.tags9 = '2';
        }

        this.retornoPessoa = await this.pessoaService.salvar(this.pessoa);

        this.setState({ pessoa: this.retornoPessoa.objeto });
    }

    render() {
        return <>
            <Header
                selecionado={this.constante.Telas.Iexcombo}
                pessoaUsuario={this.state.pessoaUsuario}
            />


            {this.state.pessoa.seqPessoa ?
                <PessoaIexcombo
                    setState={pState => this.setState(pState)}
                    pessoa={this.state.pessoa}
                    pessoaRelacionada={this.state.pessoaRelacionada}
                    listaPessoaRelacionada={this.state.listaPessoaRelacionada}
                    constante={this.constante}
                    salvarRelacionamento={() => this.salvarRelacionamento()}
                    salvarPessoa={() => this.salvarPessoa()}
                    deletarRelacionamento={(pPessoa) => this.deletarRelacionamento(pPessoa)}
                    selecionarPessoaRelacionamento={(pPessoa) => this.selecionarPessoaRelacionamento(pPessoa)}
                    util={this.util}
                    gerarFinanceiro={() => this.gerarFinanceiro()}
                    enviarAIG={() => this.enviarAIG()}
                    enderecoPessoa={this.state.enderecoPessoa}
                /> : <div className="sis-iex-container">
                    <main className="sis-iex-main">
                        <div className="sis-iex-wrapper">
                            <div>
                                <div>
                                    <label>Pesquisar</label>
                                </div>
                                <input onChange={(pTexto) => this.setState({ conteudo: pTexto.target.value })} />
                            </div>
                            <button
                                className="sis-iex-botao"
                                onClick={() => this.listar()}
                            >
                                Buscar
                            </button>
                        </div>

                        <table className="sis-iex-main__tabela">
                            <thead className="sis-iex-main__thead">
                                <tr>
                                    <th className="sis-iex-main__th">id</th>
                                    <th className="sis-iex-main__th">Nome</th>
                                    <th className="sis-iex-main__th">Email</th>
                                    <th className="sis-iex-main__th">Ação</th>
                                </tr>
                            </thead>

                            <tbody>
                                {this.state.listaPessoa.map(pPessoa =>
                                    <tr>
                                        <td className="sis-iex-main__td">{pPessoa.seqPessoa}</td>
                                        <td className="sis-iex-main__td">{pPessoa.nome}</td>
                                        <td className="sis-iex-main__td">{pPessoa.emailPlataformaLirida
                                        }</td>
                                        <td className="sis-iex-main__td">
                                            <button
                                                className="sis-iex-botao-detalhe"
                                                onClick={() => this.selecionarPessoa(pPessoa)}
                                            >
                                                <HiEye />
                                            </button>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </main>
                </div>}
        </>
    }
}