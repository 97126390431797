import { Component } from 'react';
import './style.css';

export default class ResumoCompra extends Component {
    render() {
        return <div className='sis-ch-rc-container'>
            <h2 className='sis-ch-rc-titulo'>Resumo da compra</h2>

            <div className='sis-ch-rc-wrapper'>
                {this.props.listaCompra.map(item =>
                    <div className='sis-ch-rc-wrapper__div'>
                        <div>
                            <p className='sis-ch-rc-wrapper__item'>{item.itemNome}</p>
                            <p className='sis-ch-rc-wrapper__titulo-pequeno'>Preço unitáro</p>
                            <p className='sis-ch-rc-wrapper__preco'>
                                {this.props.formatarValor(item.precoTabelaMaximo)}
                            </p>
                        </div>

                        <div className='sis-ch-rc-wrapper__quantidade'>
                            <p className='sis-ch-rc-wrapper__titulo-pequeno'>
                                Quantidade
                            </p>
                            <div className='sis-ch-rc-wrapper__wrapper-botao'>
                                <button
                                    onClick={() => this.props.diminuirQuantidade(item)}
                                    className='sis-ch-rc-wrapper__botao-quantidade'
                                >
                                    -
                                </button>
                                <span className='sis-ch-rc-wrapper__preco'>
                                    {item.quantidade}
                                </span>
                                <button
                                    onClick={() => this.props.aumentarQuantidade(item)}
                                    className='sis-ch-rc-wrapper__botao-quantidade'
                                >
                                    +
                                </button>
                            </div>
                        </div>

                        <p className='sis-ch-rc-wrapper__preco'>
                            {this.props.formatarValor(Number(item.quantidade) * Number(item.precoTabelaMaximo))}
                        </p>
                    </div>
                )}
            </div>
        </div>
    }
}