import { Component } from 'react';
import './style.css';

export default class Pagamento extends Component {
    render() {
        return <div className="sis-ch-pg-container">
            <h2 className="sis-ch-pg-titulo">Resumo final</h2>

            <div className='sis-ch-pg-wrapper'>
                <h2>{this.props.pessoa.nome}</h2>
                <h3>{`${this.props.endereco.logradouro}, ${this.props.endereco.numero}, ${this.props.endereco.bairro} - ${this.props.endereco.cidade}, ${this.props.endereco.uf}`}</h3>
                <p>Melhor data para instalação: {this.props.formatarDataInput(this.props.documento.dataProgramada)}</p>

                <ul>
                    {this.props.listaDocumentoItem.map(item =>
                        <li>
                            <p>{`x${item.quantidade} ${item.itemNome} ${this.props.formatarValor(item.precoUnitario)}`}</p>
                        </li>
                    )}
                </ul>


                <h2>Total: {this.props.formatarValor(this.props.retornaValorTotal())}</h2>
            </div>
        </div>
    }
}