import DocumentoService from "lirida-back-service/Servicos/Documento/DocumentoService";
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import { Component, createRef } from "react";
import { HiEye } from "react-icons/hi";
import Constante from "../../../../util/Constante";
import Util from "../../../../util/Util";
import Header from "../Header";
import './style.css';

export default class MapaDocumento extends Component {
    util = new Util();
    constante = new Constante();
    documentoService = new DocumentoService(this.constante.token, this.constante.url_base);

    mapaRef = createRef();

    Modo = {
        Lista: 'Lista',
        Mapa: 'Mapa'
    }

    state = {
        dataCadastro_inicial: '',
        dataCadastro_final: '',
        mapaCarregando: false,
        listaDocumentoItem: [],
        modo: this.Modo.Mapa,
        pessoaUsuario: new Pessoa()
    }

    componentDidMount() {
        this.iniciar(this.state.modo);
    }

    coordenadasBrasil = {
        lat: -14.2401,
        lng: -53.1805,
    }

    async iniciar(pModo) {
        console.log(pModo);
        console.log(this.Modo.Lista)
        this.setState({ modo: pModo })
        this.listaPesquisa = [];

        this.pessoaUsuario = await this.util.buscarLocalStorage('PESSOA_USUARIO');

        this.util.validarAcessoTela(this.constante.Telas.MapaDocumento, this.pessoaUsuario);

        this.listaDocumentoItem = await this.listar();

        if (pModo === this.Modo.Mapa) {
            const map = new window.google.maps.Map(this.mapaRef.current, {
                center: this.coordenadasBrasil,
                zoom: 4,
            });

            for (let i = 0; i < this.listaDocumentoItem.length; i++) {
                const contentString =
                    "</div>" +
                    '<h2>' + this.listaDocumentoItem[i].seqDocumento + '</h2>' +
                    "</div>";

                const infowindow = new window.google.maps.InfoWindow({
                    content: contentString,
                    maxWidth: 200,
                });

                const marker = new window.google.maps.Marker({
                    position: {
                        lat: Number(this.listaDocumentoItem[i].endLatitude),
                        lng: Number(this.listaDocumentoItem[i].endLongitude),
                    },
                    map,
                })

                marker.addListener("click", () => {
                    infowindow.open({
                        anchor: marker,
                        map,
                        shouldFocus: false,
                    });
                });
            }
        }

        this.setState({
            listaDocumentoItem: this.listaDocumentoItem,
            pessoaUsuario: this.pessoaUsuario,
        });
    }

    async listar() {
        this.listaPesquisa.push(
            this.util.retornaPesquisa(
                "documento_item.seq_usuario",
                this.constante.seqUsuario,
                "",
                "NUMERO"
            )
        );

        if (this.state.dataCadastro_inicial) {
            this.listaPesquisa.push(
                this.util.retornaPesquisa(
                    "documento_item.data_cadastro",
                    this.util.formatarDataInput(this.state.dataCadastro_inicial),
                    "AND",
                    "DATA_INICIAL"
                )
            );
        }

        if (this.state.dataCadastro_final) {
            this.listaPesquisa.push(
                this.util.retornaPesquisa(
                    "documento_item.data_cadastro",
                    this.util.formatarDataInput(this.state.dataCadastro_final),
                    "AND",
                    "DATA_FINAL"
                )
            );
        }

        this.retornoListaDocumento = await this.documentoService.listarItemComFiltro(this.listaPesquisa);
        console.log(this.retornoListaDocumento);
        this.listaDocumento = this.retornoListaDocumento.objeto;
        return this.listaDocumento;
    }

    selecionarDocumentoItem(pDocumentoItem) {
        this.setState({ documentoItem: pDocumentoItem })
    }

    render() {
        return <div className="sis-mpdocs-container">

            <Header
                pessoaUsuario={this.state.pessoaUsuario}
                selecionado={this.constante.Telas.MapaDocumento} />

            <main className='sis-mpdocs-main'>
                <div className='sis-mpdocs-main__container'>
                    <h2 className='sis-mpdocs-main__titulo'>
                        Mapa Documento
                    </h2>

                    <div className="sis-mpdocs-main__wrapper">
                        <div>
                            <div>
                                <label>Modo de Exbição</label>
                            </div>
                            <div>
                                <select
                                    value={this.state.modo}
                                    onChange={(pValor) => {
                                        this.iniciar(pValor.target.value);
                                    }}>
                                    <option value={this.Modo.Mapa}>Mapa</option>
                                    <option value={this.Modo.Lista}>Lista</option>
                                </select>
                            </div>
                        </div>

                        <div>
                            <div>
                                <label>Data cadastro</label>
                            </div>
                            <div className='sis-mpdocs-main__input-wrapper'>
                                <input
                                    onChange={pTexto => this.setState({ dataCadastro_inicial: pTexto.target.value })}
                                    value={this.state.dataCadastro_inicial}
                                    className="sis-mpdocs-main__input"
                                    type="date"
                                />
                                <input
                                    onChange={pTexto => this.setState({ dataCadastro_final: pTexto.target.value })}
                                    value={this.state.dataCadastro_final}
                                    className="sis-mpdocs-main__input"
                                    type="date"
                                />
                            </div>
                        </div>
                    </div>

                    <div className='sis-mpdocs-main__botao-wrapper'>
                        <button
                            onClick={() => this.iniciar(this.state.modo)}
                            className='sis-mpdocs-main__botao-busca'
                        >
                            Buscar
                        </button>
                    </div>
                </div>

                {this.state.modo === this.Modo.Mapa && <div ref={this.mapaRef} style={{ width: '100%', height: '500px' }}></div>}

                {this.state.modo === this.Modo.Lista &&
                    <table>
                        <thead>
                            <tr>
                                <th>id</th>
                                <th>Status</th>
                                <th>Nome</th>
                                <th>Quantidade</th>
                                <th>Preco Unitário</th>
                                <th>Valor Total</th>
                                <th>Valor Técnico</th>
                                <th>Margem</th>
                                <th>Data Programada</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.listaDocumentoItem.map(pDocumentoItem =>
                                <tr>
                                    <td className='sis-docs-div2__td'>{pDocumentoItem.seqDocumentoItem}</td>
                                    <td className='sis-docs-div2__td'>
                                        {pDocumentoItem.status === this.constante.statusServicoNaoPago ?
                                            'Não pago' : 'Pago'
                                        }
                                    </td>
                                    <td className='sis-docs-div2__td'>{pDocumentoItem.itemNomeVitrine}</td>
                                    <td className='sis-docs-div2__td'>{pDocumentoItem.quantidade}</td>
                                    <td className='sis-docs-div2__td'>{this.util.formatarValor(pDocumentoItem.precoTabelaMaximo)}</td>
                                    <td className='sis-docs-div2__td'>{this.util.formatarValor(pDocumentoItem.valorTotal)}</td>
                                    <td className='sis-docs-div2__td'>{this.util.formatarValor(pDocumentoItem.precoTabelaMinimo * pDocumentoItem.quantidade)}</td>
                                    <td className='sis-docs-div2__td'>{this.util.formatarValor(pDocumentoItem.valorTotal - (pDocumentoItem.precoTabelaMinimo * pDocumentoItem.quantidade))}</td>
                                    <td className='sis-docs-div2__td'>{this.util.formatarData(pDocumentoItem.dataProgramada)}</td>
                                    <td className='sis-docs-div2__td'>
                                        <button
                                            className='sis-docs-doc-botao-detalhe'
                                            onClick={() => this.selecionarDocumentoItem(pDocumentoItem)}
                                        >
                                            <HiEye />
                                        </button>
                                    </td>
                                </tr>

                            )}
                        </tbody>
                    </table>
                }
            </main >
        </div >
    }
}