import DepartamentoService from 'lirida-back-service/Servicos/Departamento/DepartamentoService';
import Documento from 'lirida-back-service/Servicos/Documento/Documento';
import DocumentoItem from 'lirida-back-service/Servicos/Documento/DocumentoItem';
import DocumentoService from 'lirida-back-service/Servicos/Documento/DocumentoService';
import DocumentoArquivo from 'lirida-back-service/Servicos/DocumentoArquivo/DocumentoArquivo';
import DocumentoAtendimento from 'lirida-back-service/Servicos/DocumentoAtendimento/DocumentoAtendimento';
import DocumentoCaracteristicaDocumentoService from 'lirida-back-service/Servicos/DocumentoCaracteristicaDocumento/DocumentoCaracteristicaDocumentoService';
import DocumentoItemCaracteristicaDocumentoItem from 'lirida-back-service/Servicos/DocumentoItemCaracteristicaDocumentoItem/DocumentoItemCaracteristicaDocumentoItem';
import DocumentoItemCaracteristicaDocumentoItemService from 'lirida-back-service/Servicos/DocumentoItemCaracteristicaDocumentoItem/DocumentoItemCaracteristicaDocumentoItemService';
import DocumentoRelacionamento from 'lirida-back-service/Servicos/DocumentoRelacionamento/DocumentoRelacionamento';
import Financeiro from 'lirida-back-service/Servicos/Financeiro/Financeiro';
import FinanceiroService from 'lirida-back-service/Servicos/Financeiro/FinanceiroService';
import Pesquisa from 'lirida-back-service/Servicos/Pesquisa/Pesquisa';
import Pessoa from 'lirida-back-service/Servicos/Pessoa/Pessoa';
import PessoaService from 'lirida-back-service/Servicos/Pessoa/PessoaService';
import TabelaPreco from 'lirida-back-service/Servicos/TabelaPreco/TabelaPreco';
import TabelaPrecoService from 'lirida-back-service/Servicos/TabelaPreco/TabelaPrecoService';
import TipoArquivo from 'lirida-back-service/Servicos/TipoArquivo/TipoArquivo';
import TipoArquivoService from 'lirida-back-service/Servicos/TipoArquivo/TipoArquivoService';
import TipoAtendimento from 'lirida-back-service/Servicos/TipoAtendimento/TipoAtendimento';
import TipoAtendimentoService from 'lirida-back-service/Servicos/TipoAtendimento/TipoAtendimentoService';
import TipoDocumentoProcesso from 'lirida-back-service/Servicos/TipoDocumentoProcesso/TipoDocumentoProcesso';
import TipoDocumentoProcessoService from 'lirida-back-service/Servicos/TipoDocumentoProcesso/TipoDocumentoProcessoService';
import TipoRelacionamento from 'lirida-back-service/Servicos/TipoRelacionamento/TipoRelacionamento';
import TipoRelacionamentoService from 'lirida-back-service/Servicos/TipoRelacionamento/TipoRelacionamentoService';
import VwContadorDocumento from 'lirida-back-service/Servicos/VwContadorDocumento/VwContadorDocumento';
import VwContadorDocumentoItem from 'lirida-back-service/Servicos/VwContadorDocumentoItem/VwContadorDocumentoItem';
import { Component } from 'react';
import Loading from '../../../../components/Loading';
import Constante from '../../../../util/Constante';
import Util from '../../../../util/Util';
import Header from '../Header';
import DocumentoItemPg from './components/DocumentoItemPg';
import DocumentoPg from './components/DocumentoPg';
import './style.css';
import { HiEye } from 'react-icons/hi';

export default class Documentos extends Component {
    util = new Util();
    constante = new Constante();
    tipoDocumentoProcessoService = new TipoDocumentoProcessoService(this.constante.token, this.constante.url_base);
    documentoService = new DocumentoService(this.constante.token, this.constante.url_base);
    pessoaService = new PessoaService(this.constante.token, this.constante.url_base);
    documentoCaracteristicaDocumentoService = new DocumentoCaracteristicaDocumentoService(this.constante.token, this.constante.url_base);
    tipoRelacionamentoService = new TipoRelacionamentoService(this.constante.token, this.constante.url_base);
    tabelaPrecoService = new TabelaPrecoService(this.constante.token, this.constante.url_base);
    departamentoService = new DepartamentoService(this.constante.token, this.constante.url_base);
    tipoArquivoService = new TipoArquivoService(this.constante.token, this.constante.url_base);
    tipoAtendimentoService = new TipoAtendimentoService(this.constante.token, this.constante.url_base);
    vwContadorDocumento = new VwContadorDocumento();
    vwContadorDocumentoItem = new VwContadorDocumentoItem();
    documentoItemCaracteristicaDocumentoItemService = new DocumentoItemCaracteristicaDocumentoItemService(this.constante.token, this.constante.url_base);
    financeiroService = new FinanceiroService(this.constante.token, this.constante.url_base);

    state = {
        processoCarregando: false,
        listaCarregando: false,
        listaDocumentoItemRelacionamento: [],
        listaDocumentoItemCaracteristicaDocumentoItem: [],
        documentoItemCaracteristicaDocumentoItem: new DocumentoItemCaracteristicaDocumentoItem(),
        pessoaUsuario: new Pessoa(),
        documento: new Documento(),
        documentoItem: new DocumentoItem(),
        quantidade: 1,
        filtro: "com filtro",
        listaDocumento: [],
        listaPessoa: [],
        listaTabelaPreco: [],
        listaDocumentoItem: [],
        listaVwContadorDocumentoItem: [],
        vwContadorDocumento: new VwContadorDocumentoItem(),
        listaDepartamento: [],
        tabelaPreco: new TabelaPreco(),
        urlBaseArquivo: undefined,
        tela: "1",
        conteudo: "",
        listaTipoDocumentoProcesso: [],
        mensagemVisivel: false,
        mensagem: "",
        popupVisivel: false,
        mensagemExcluir: "",
        dropdownVisivel: false,
        listaTipoDocumentoProcessoItem: [],
        tipoDocumentoProcesso: new TipoDocumentoProcesso(),
        carregando: false,
        tag: "dados_serviços",
        listaDocumentoRelacionamento: [],
        listaPessoaDoRelacionamento: [],
        documentoRelacionamento: new DocumentoRelacionamento(),
        tipoRelacionamento: new TipoRelacionamento(),
        listaTipoRelacionamento: [],
        popupDeletarDocumentoRelacionamentoVisivel: false,
        listaDocumentoCaracteristica: [],
        listaTipoArquivo: [],
        listaDocumentoArquivo: [],
        documentoArquivo: new DocumentoArquivo(),
        tipoArquivo: new TipoArquivo(),
        popupDeletarDocumentoArquivoVisivel: false,
        listaDocumentoAtendimento: [],
        listaTipoAtendimento: [],
        documentoAtendimento: new DocumentoAtendimento(),
        tipoAtendimento: new TipoAtendimento(),
    };

    async componentDidMount() {
        await this.iniciar();
    }

    async iniciar() {
        this.urlBase = await this.util.buscarUrlBaseArquivo();
        this.pessoaUsuario = await this.util.buscarLocalStorage("PESSOA_USUARIO");

        this.retorno = this.util.validarAcessoTela(this.constante.Telas.Documento, this.pessoaUsuario);
        if (!this.retorno) {
            return;
        }

        this.setState({
            processoCarregando: true,
            listaCarregando: true,
            pessoaUsuario: this.pessoaUsuario,
            urlBaseArquivo: this.urlBase,
        });

        let retornoDepartamento = await this.departamentoService.listar(this.constante.seqUsuario);

        console.log('retorno departamento');
        console.log(retornoDepartamento);

        this.retornoVwContadorDocumentoItem = await this.documentoService.listarContadorDocumentoItem(
            this.constante.seqUsuario, this.constante.seqTipoDocumento
        );

        console.log('retorno vw contador');
        console.log(this.retornoVwContadorDocumentoItem)

        let retornoListaDocumentoItem = await this.listarDocumentoItemComDetalhes(this.retornoVwContadorDocumentoItem.objeto[0].seqTipoDocumentoProcesso);
        this.setState({
            processoCarregando: false,
            listaCarregando: false,
            listaDocumentoItem: retornoListaDocumentoItem.objeto,
            listaDepartamento: retornoDepartamento.objeto,
        });

        this.listar(this.retornoVwContadorDocumentoItem.objeto[0]);
    }

    async listar(pTipoDocumentoProcesso) {
        console.log(pTipoDocumentoProcesso);
        let tipoDocumentoProcesso = pTipoDocumentoProcesso;

        this.retornoVwContadorDocumentoItem = await this.documentoService.listarContadorDocumentoItem(
            this.constante.seqUsuario, this.constante.seqTipoDocumento
        );

        this.setState({
            listaCarregando: true,
            vwContadorDocumentoItem: pTipoDocumentoProcesso,
            listaVwContadorDocumentoItem: this.retornoVwContadorDocumentoItem.objeto,
            listaDocumentoItem: [],
        });

        console.log(pTipoDocumentoProcesso);

        if (!pTipoDocumentoProcesso) {
            tipoDocumentoProcesso = this.state.tipoDocumentoProcesso;
        }

        let listaPesquisa = [
            this.criarObjetoPesquisa(
                "documento.seq_usuario_vendedor",
                this.constante.seqUsuarioVendedor,
                "",
                "NUMERO"
            ),
            this.criarObjetoPesquisa(
                "documento_item.seq_tipo_documento_processo",
                tipoDocumentoProcesso.seqTipoDocumentoProcesso,
                "AND",
                "NUMERO"
            ),
        ];

        this.retornoListaDocumentoItem = await this.documentoService.listarItemComFiltro(listaPesquisa);
        console.log(this.retornoListaDocumentoItem);
        this.setState({
            listaCarregando: false,
            listaDocumentoItem: this.retornoListaDocumentoItem.objeto,
        });
    }



    async listarRelacionamentoItem(pDocumentoItem) {
        this.retornoDocumentoItemRelacionamento = await this.documentoService.listarRelacionamentoItem(pDocumentoItem);
        this.setState({
            documentoItem: pDocumentoItem,
            listaDocumentoItemRelacionamento: this.retornoDocumentoItemRelacionamento.objeto,
        })
    }

    async selecionar(pDocumento) {
        let documento = pDocumento;

        console.log(documento)

        this.setState({
            carregando: true,
            dropdownVisivel: false,
            mensagemVisivel: false,
            documento: documento,
            tela: "2",
        });

        await this.listarDocumentoItem(documento);
        await this.listarTipoDocumentoProcessoItem(documento.seqTipoDocumentoProcesso);
        await this.listarDocumentoRelacionamento(documento);
        await this.listarCaracteristicaDocumento(documento);

        this.setState({ carregando: false, });
    }

    async listarDocumentoItem(pDocumento) {
        let documento;

        if (pDocumento) {
            documento = pDocumento;
        } else {
            documento = this.state.documento;
        }

        let retornoDocumentoItem = await this.documentoService.listarDocumentoItem(
            documento
        );
        this.setState({ listaDocumentoItem: retornoDocumentoItem.objeto });
    }

    async listarTipoDocumentoProcessoItem(pSeqTipoDocumentoProcesso) {
        let retorno = await this.tipoDocumentoProcessoService.listarTipoDocumentoProcessoItem(
            pSeqTipoDocumentoProcesso
        );
        this.setState({ listaTipoDocumentoProcessoItem: retorno.objeto });
    }

    async listarDocumentoRelacionamento(pDocumento) {
        let documento;

        if (pDocumento) {
            documento = pDocumento;
        } else {
            documento = this.state.documento;
        }

        let retornoListaDocumentoRelacionamento = await this.documentoService.listarRelacionamento(
            documento
        );
        this.setState({
            listaDocumentoRelacionamento: retornoListaDocumentoRelacionamento.objeto
        })
    }

    async listarCaracteristicaDocumento(pDocumento) {
        let documento;

        if (pDocumento) {
            documento = pDocumento;
        } else {
            documento = this.state.documento;
        }

        let retorno = await this.documentoCaracteristicaDocumentoService.listar(documento);
        this.setState({ listaDocumentoCaracteristica: retorno.objeto })
    }

    async listarCaracteristicaDocumentoItem(pDocumentoItem) {
        this.documentoItem = new DocumentoItem();
        this.documentoItemCaracteristicaDocumentoItem = new DocumentoItemCaracteristicaDocumentoItem();

        if (pDocumentoItem) {
            this.documentoItem = pDocumentoItem;
        } else {
            this.documentoItem = this.state.documentoItem;
        }

        this.retorno = await this.documentoItemCaracteristicaDocumentoItemService.listar(this.documentoItem);

        this.listaDocumentoItemCaracteristicaDocumentoItem = this.retorno.objeto;

        for (let i = 0; i < this.listaDocumentoItemCaracteristicaDocumentoItem.length; i++) {
            if (this.listaDocumentoItemCaracteristicaDocumentoItem[i].marcado === "true") {
                this.documentoItemCaracteristicaDocumentoItem = this.listaDocumentoItemCaracteristicaDocumentoItem[i];
            }
        }

        this.setState({
            listaDocumentoItemCaracteristicaDocumentoItem: this.listaDocumentoItemCaracteristicaDocumentoItem,
            documentoItemCaracteristicaDocumentoItem: this.documentoItemCaracteristicaDocumentoItem,
        });
    }

    marcarDesmarcarCaracteristica(pIndex) {
        let listaDocumentoCaracteristica = this.state.listaDocumentoCaracteristica;

        if (listaDocumentoCaracteristica[pIndex].marcado === "true") {
            listaDocumentoCaracteristica[pIndex].marcado = "false";
        } else if (listaDocumentoCaracteristica[pIndex].marcado === "false") {
            listaDocumentoCaracteristica[pIndex].marcado = "true";
        }

        listaDocumentoCaracteristica[pIndex].seqDocumento = this.state.documento.seqDocumento;

        this.setState({
            listaDocumentoCaracteristica: listaDocumentoCaracteristica,
        })
    }

    criarObjetoPesquisa(pCampo, pConteudo, pOperacao, pTipo) {
        let pesquisa = new Pesquisa();

        pesquisa.campo = pCampo;
        pesquisa.conteudo = pConteudo;
        pesquisa.operacao = pOperacao;
        pesquisa.tipo = pTipo;

        return pesquisa;
    }

    async listarDocumentoItemComDetalhes(pSeqTipoDocumentoProcesso) {
        this.pesquisa = new Pesquisa();
        this.pesquisa.campo = 'documento_item.seq_tipo_documento_processo';
        this.pesquisa.conteudo = pSeqTipoDocumentoProcesso;
        this.pesquisa.tipo = 'NUMERO';
        this.pesquisa.operacao = 'VAZIO';

        this.retorno = await this.documentoService.listarItemComFiltro([this.pesquisa]);
        console.log('a')
        return this.retorno;
    }

    async selecionarDocumentoItem(pDocumentoItem) {
        await this.listarRelacionamentoItem(pDocumentoItem);
        await this.listarCaracteristicaDocumentoItem(pDocumentoItem);
    }

    async gerarFinanceiro() {
        /* GERANDO FINANCEIRO */
        this.documentoItem = this.state.documentoItem;
        this.financeiro = new Financeiro();

        this.retornoDocumento = await this.documentoService.buscar(this.constante.seqUsuario, this.documentoItem.seqDocumento);
        this.documento = this.retornoDocumento.objeto;

        this.financeiro.seqUsuario = this.documento.seqUsuario;
        this.financeiro.seqPessoa = this.documentoItem.seqPessoaRecebedor;
        this.financeiro.seqContaBancaria = this.constante.seqContaBancaria;
        this.financeiro.seqContaFinanceira = this.constante.seqContaFinanceiraPagar;
        this.financeiro.valorProgramado = this.documentoItem.precoTabelaMinimo * this.documentoItem.quantidade;
        this.financeiro.dataProgramado = new Date().toLocaleDateString().split('/').join('-') + 'T00:00:00';
        this.financeiro.documento = this.state.pessoaUsuario.documento;
        this.financeiro.tipoDocumento = this.state.pessoaUsuario.tipoDocumento;
        this.financeiro.nome = this.documento.vendedorNome;
        this.financeiro.seqTipoDocumento = this.documento.seqTipoDocumento;
        this.financeiro.qtdeBaixa = 0;
        this.financeiro.seqFormaPagamento = this.constante.seqFormaPagamentoPix;
        this.financeiro.seqDocumento = this.documento.seqDocumento;
        this.financeiro.seqDocumentoItem = this.documentoItem.seqDocumentoItem;
        this.financeiro.tagn0 = this.documentoItem.tagn0;

        this.financeiro.tags1 = this.documentoItem.itemNomeVitrine;
        this.financeiro.tags2 = `${this.documentoItem.endBairro} - ${this.documentoItem.endCidade} ${this.documentoItem.endUf}`;

        // Trocar para data que o tecnico foi na instalacao e nao a data que o cliente escolheu
        this.financeiro.tagd0 = this.documentoItem.dataProgramada;
        //

        console.log(this.financeiro)

        this.retornoFinanceiro = await this.financeiroService.salvar(this.financeiro);

        console.log(this.retornoFinanceiro);

        this.documento.seqTipoDocumentoProcesso = this.constante.seqTipoDocumentoProcessoFinalizado;
        this.documentoItem.seqTipoDocumentoProcesso = this.constante.seqTipoDocumentoProcessoFinalizado;

        await this.documentoService.salvar(this.documento);
        await this.documentoService.salvarItem(this.documentoItem);
    }

    render() {
        return this.state.pessoaUsuario.seqPessoa && <div className='sis-docs-container'>
            {this.state.documentoItem.seqDocumentoItem &&
                <DocumentoItemPg
                    util={this.util}
                    constante={this.constante}
                    gerarFinanceiro={() => this.gerarFinanceiro()}
                    documentoItemCaracteristicaDocumentoItem={this.state.documentoItemCaracteristicaDocumentoItem}
                    listaDocumentoItemRelacionamento={this.state.listaDocumentoItemRelacionamento}
                    documentoItem={this.state.documentoItem}
                    setState={pState => this.setState(pState)}
                />
            }

            <Header
                pessoaUsuario={this.state.pessoaUsuario}
                selecionado={this.constante.Telas.Documento} />

            {this.state.documento.seqDocumento &&
                <DocumentoPg
                    util={this.util}
                    constante={this.constante}
                    selecionarDocumentoItem={pDocumentoItem => this.selecionarDocumentoItem(pDocumentoItem)}
                    listaDocumentoItem={this.state.listaDocumentoItem}
                    documento={this.state.documento}
                    setState={pState => this.setState(pState)}
                />
            }


            <main className='sis-docs-main'>
                <div className='sis-docs-div1'>
                    <div className='sis-docs-div1__container'>

                        {this.state.processoCarregando && <Loading />}

                        {this.state.listaDepartamento.map((pDepartamento, pDepartamentoIndex) =>
                            <div className='sis-docs-div1__wrapper'>
                                <h2 className='sis-docs-div1__titulo-departamento'>
                                    {pDepartamento.nome} {pDepartamento.seqDepartamento}
                                </h2>

                                {this.state.listaVwContadorDocumentoItem.map(
                                    (pVwContadorDocumentoItem) =>
                                        <div className='sis-docs-div1__botao-wrapper'>
                                            {pVwContadorDocumentoItem.seqDepartamento === pDepartamento.seqDepartamento &&
                                                <button
                                                    onClick={() => this.listar(pVwContadorDocumentoItem)}
                                                    className={
                                                        this.state.vwContadorDocumentoItem.seqTipoDocumentoProcesso ===
                                                            pVwContadorDocumentoItem.seqTipoDocumentoProcesso ?
                                                            'sis-docs-div1__processo sis-docs-div1__selecionado' :
                                                            'sis-docs-div1__processo'}>
                                                    {pVwContadorDocumentoItem.tipoDocumentoProcessoNome} ({pVwContadorDocumentoItem.contador})
                                                </button>
                                            }
                                        </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>

                <div className='sis-docs-div2'>
                    <div className='sis-docs-div2__wrapper'>
                        <h2 className='sis-docs-div2__titulo'>Ordens de serviço</h2>

                        <table className='sis-docs-div2__tabela'>
                            <thead>
                                <tr className='sis-docs-div2__thead'>
                                    <th className='sis-docs-div2__th'>id</th>
                                    <th className='sis-docs-div2__th'>Status</th>
                                    <th className='sis-docs-div2__th'>Nome</th>
                                    <th className='sis-docs-div2__th'>Quantidade</th>
                                    <th className='sis-docs-div2__th'>Preço Unitário</th>
                                    <th className='sis-docs-div2__th'>Valor total</th>
                                    <th className='sis-docs-div2__th'>Valor técnico</th>
                                    <th className='sis-docs-div2__th'>Margem</th>
                                    <th className='sis-docs-div2__th'>Data programada</th>
                                    <th className='sis-docs-div2__th'>Ação</th>
                                </tr>
                            </thead>

                            <tbody>
                                {this.state.listaDocumentoItem.map(pDocumentoItem =>
                                    <>
                                        <tr>
                                            <td className='sis-docs-div2__td'>{pDocumentoItem.seqDocumentoItem}</td>
                                            <td className='sis-docs-div2__td'>
                                                {pDocumentoItem.status === this.constante.statusServicoNaoPago ?
                                                    'Não pago' : 'Pago'
                                                }
                                            </td>
                                            <td className='sis-docs-div2__td'>{pDocumentoItem.itemNomeVitrine}</td>
                                            <td className='sis-docs-div2__td'>{pDocumentoItem.quantidade}</td>
                                            <td className='sis-docs-div2__td'>{this.util.formatarValor(pDocumentoItem.precoTabelaMaximo)}</td>
                                            <td className='sis-docs-div2__td'>{this.util.formatarValor(pDocumentoItem.valorTotal)}</td>
                                            <td className='sis-docs-div2__td'>{this.util.formatarValor(pDocumentoItem.precoTabelaMinimo * pDocumentoItem.quantidade)}</td>
                                            <td className='sis-docs-div2__td'>{this.util.formatarValor(pDocumentoItem.valorTotal - (pDocumentoItem.precoTabelaMinimo * pDocumentoItem.quantidade))}</td>
                                            <td className='sis-docs-div2__td'>{this.util.formatarData(pDocumentoItem.dataProgramada)}</td>
                                            <td className='sis-docs-div2__td'>
                                                <button
                                                    className='sis-docs-doc-botao-detalhe'
                                                    onClick={() => this.selecionarDocumentoItem(pDocumentoItem)}>
                                                    <HiEye />
                                                </button>
                                            </td>
                                        </tr>
                                    </>
                                )}
                            </tbody>
                        </table>

                        {this.state.listaCarregando && <Loading />}

                        {!this.state.listaCarregando && this.state.listaDocumentoItem.length === 0 ?
                            <p>Nenhuma ordem de serviço encontrada</p>
                            : null}
                    </div>
                </div>
            </main>

        </div>
    }
}