export default class WhatsAppService {
    url_base = 'https://api.hyperflow.global/service/a70d3487-f572-4fe4-9ac2-18b97aa72386';

    async lembrarTecnicoUmDiaAntes(pNumero, pNome, pHora) {
        this.body = {
            phone: pNumero,
            template: "lembrete_um_dia_antes",
            param1: pNome,
            param2: pHora,
        }

        this.header = new Headers();
        this.header.append('api-key', 'es9FvneS2jxb5hI7i2ieUO5H');
        this.header.append('sender', 'IEXServer');
        this.header.append('Content-Type', 'application/json');

        await fetch(this.url_base, {
            headers: this.header,
            method: 'POST',
            body: JSON.stringify(this.body),
        });
    }

    async notificarTecnicoNovoServico(pNumero, pNome, pHora) {
        this.body = {
            phone: pNumero,
            template: "novo_servico_tecnico",
            param1: pNome,
            param2: pHora,
        }

        this.header = new Headers();
        this.header.append('api-key', 'es9FvneS2jxb5hI7i2ieUO5H');
        this.header.append('sender', 'IEXServer');
        this.header.append('Content-Type', 'application/json');

        await fetch(this.url_base, {
            headers: this.header,
            method: 'POST',
            body: JSON.stringify(this.body),
        });
    }

    async notificarClienteTecnicoRejeitado(pNumero, pNome) {
        this.body = {
            phone: pNumero,
            template: "tecnico_rejeitou_os",
            param1: pNome,
        }

        this.header = new Headers();
        this.header.append('api-key', 'es9FvneS2jxb5hI7i2ieUO5H');
        this.header.append('sender', 'IEXServer');
        this.header.append('Content-Type', 'application/json');

        await fetch(this.url_base, {
            headers: this.header,
            method: 'POST',
            body: JSON.stringify(this.body),
        });
    }

    async notificarClienteAceiteTecnico(pNumeroCliente, pNome, pNumeroTecnico, pData, pHora) {
        this.body = {
            phone: pNumeroCliente,
            template: "agendamento_tecnico_para_cliente",
            param1: pNome,
            param2: pNumeroTecnico,
            param3: pData,
            param4: pHora,
        }

        this.header = new Headers();
        this.header.append('api-key', 'es9FvneS2jxb5hI7i2ieUO5H');
        this.header.append('sender', 'IEXServer');
        this.header.append('Content-Type', 'application/json');

        await fetch(this.url_base, {
            headers: this.header,
            method: 'POST',
            body: JSON.stringify(this.body),
        });
    }

    async enviarLinkPagamento(pNumero, pChave) {
        this.body = {
            phone: '55' + String(pNumero).replace('(', '').replace(')', '').replace('-', ''),
            template: "link_pagamento_cliente",
            param1: '?idf=' + pChave,
        }

        this.header = new Headers();
        this.header.append('api-key', 'es9FvneS2jxb5hI7i2ieUO5H');
        this.header.append('sender', 'IEXServer');
        this.header.append('Content-Type', 'application/json');

        await fetch(this.url_base, {
            headers: this.header,
            method: 'POST',
            body: JSON.stringify(this.body),
        });
    }
}