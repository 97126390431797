import { Component } from 'react';
import './style.css';
import celulares from '../../../../assets/varios-celulares.png';

export default class Slide extends Component {
    render() {
        return <div className="hs-sli-container">
            <div className='hs-sli-bloco1'>
                <h1 className='hs-sli-titulo'>Facilite sua <span className='hs-sli-titulo__span'>vida</span>!</h1>
                <h2 className='hs-sli-subtitulo'>Peça uma instalação ou manutenção e tenha o serviço mais rápido do Brasil.</h2>

                <button className="hs-sli-botao">Peca ja</button>
            </div>

            <div className='hs-sli-bloco2'>
                <img
                    src={celulares}
                    className='hs-sli-imagem'
                    alt="3 celulares"
                />
            </div>
        </div>
    }
}