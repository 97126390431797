import { Component } from 'react';
import './style.css';
import logo from '../../../../assets/logo-icone-texto.png';
import {GrMenu} from 'react-icons/gr';

export default class Header extends Component {
    render() {
        return <header className='hs-header'>
            <img src={logo} alt="IEX" className='hs-header__logo' />

            {/* <nav className='hs-header__nav'>
                <a href="#" className="hs-header__link">Sobre</a>
                <a href="#" className="hs-header__link">Funcionalidade</a>
                <a href="#" className="hs-header__link">Depoimentos</a>
                <a href="#" className="hs-header__link">Parceiros</a>
            </nav> */}

            {/* <button
                onClick={() => this.props.mostrarLogin()}
                className='hs-header__botao'
            >
                Login
            </button> */}

            <button
                onClick={() => this.props.mostrarMenu()}
                className='hs-header__botao'
            >
                <GrMenu />
            </button>
        </header>
    }
}