import Pessoa from 'lirida-back-service/Servicos/Pessoa/Pessoa';
import PessoaService from 'lirida-back-service/Servicos/Pessoa/PessoaService';
import { Component } from 'react';
import { Navigate } from 'react-router-dom';
import Constante from '../../util/Constante';
import Util from '../../util/Util';
import Convite from './components/Convite';
import Footer from './components/Footer';
import Header from './components/Header';
import Login from './components/Login';
import Menu from './components/Menu';
import Porque from './components/Porque';
import Slide from './components/Slide';
import Sobre from './components/Sobre';
import './style.css';

export default class Hotsite extends Component {
    constante = new Constante();
    pessoaService = new PessoaService(this.constante.token, this.constante.url_base);
    util = new Util();

    state = {
        pessoaUsuario: '',
        senha: '',
        menuVisivel: false,
        loginVisivel: true,
        liberarAcesso: false,
    }

    mostrarLogin() {
        this.setState({
            loginVisivel: !this.state.loginVisivel,
            menuVisivel: false,
        });
    }

    mostrarMenu() {
        this.setState({ menuVisivel: !this.state.menuVisivel });
    }

    async login() {
        if (!this.state.pessoaUsuario || !this.state.senha) {
            return;
        }

        this.retornoPessoaUsuario = await this.pessoaService.validarAcesso(this.state.pessoaUsuario, this.state.senha, this.constante.seqUsuario);
        console.log(this.retornoPessoaUsuario);

        this.pessoaUsuario = new Pessoa();
        this.pessoaUsuario = this.retornoPessoaUsuario.objeto;

        if (this.retornoPessoaUsuario.resultado === false) {
            return alert(this.retornoPessoaUsuario.msg);
        }

        if (this.retornoPessoaUsuario.resultado === true) {
            this.retornoPessoa = await this.pessoaService.buscarPorEmail(
                this.state.pessoaUsuario,
                this.constante.seqUsuario
            );

            console.log(this.retornoPessoa);

            if (this.retornoPessoa.resultado === false) {
                this.pessoa = new Pessoa();

                this.pessoa.seqUsuario = this.constante.seqUsuario;
                this.pessoa.nome = this.pessoaUsuario.nome;
                this.pessoa.situacao = "ATIVO";
                this.pessoa.emailPlataformaLirida = this.pessoaUsuario.email;
                this.pessoa.tipoDocumento = "CNPJ";
                this.pessoa.documento = this.pessoaUsuario.documento;
                this.pessoa.seqPessoaPerfil = this.constante.seqPessoaPerfil;
                this.pessoa.seqTabelaPreco = this.constante.seqTabelaPreco;

                await this.pessoaService.salvar(this.pessoa);
            } else {
                this.pessoa = this.retornoPessoa.objeto;
            }

            if (this.pessoa.seqPessoaPerfil !== this.constante.seqPessoaPerfilOperacional) {
                alert('Acesso negado');
                return
            }


            this.util.salvarLocalStorage("PESSOA_USUARIO", this.pessoa);

            this.setState({ liberarAcesso: true });
        }
    }

    async recuperarSenha() {
        this.retorno = window.prompt("Informe seu e-mail");
        if (!this.retorno) {
            return
        }

        this.retornoRecuperarSenha = await this.pessoaService.recuperarSenha(this.retorno, this.constante.seqUsuario);

        window.alert(this.retornoRecuperarSenha.msg)
    }

    render() {
        return <div className='hs-container'>
            {this.state.liberarAcesso && <Navigate to="/documento" />}

            {this.state.loginVisivel &&
                <Login
                    mostrarLogin={() => this.mostrarLogin()}
                    login={() => this.login()}
                    recuperarSenha={() => this.recuperarSenha()}
                    setState={pState => this.setState(pState)}
                />
            }

            {this.state.menuVisivel &&
                <Menu
                    mostrarLogin={() => this.mostrarLogin()}
                    mostrarMenu={() => this.mostrarMenu()}
                />
            }

            {!(this.state.menuVisivel || this.state.loginVisivel) &&
                <>
                    <Header
                        mostrarMenu={() => this.mostrarMenu()}
                    />
                    <Slide />
                    <Sobre />
                    <Porque />
                    <Convite />
                    <Footer />
                </>
            }
        </div>
    }
}