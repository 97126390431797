import Documento from 'lirida-back-service/Servicos/Documento/Documento';
import { Component } from 'react';
import './style.css';
import { MdArrowBack } from 'react-icons/md';
import { HiEye } from 'react-icons/hi';

export default class DocumentoPg extends Component {
    render() {
        return <div className='sis-docs-doc-container'>
            <div className='sis-docs-doc-header'>
                <button
                    onClick={() => this.props.setState({ documento: new Documento() })}
                    className='sis-docs-doc-header__botao'
                >
                    <MdArrowBack />
                </button>
                <h2 className='sis-docs-doc-header__titulo'>Detalhe do documento</h2>
            </div>

            <div className='sis-docs-doc-wrapper'>
                <div className='sis-docs-doc-wrapper__div'>
                    <h2 className='sis-docs-doc-wrapper__titulo'>Pessoa</h2>
                    <p className='sis-docs-doc-wrapper__descricao'>
                        {this.props.documento.pessoaNome}
                    </p>
                </div>
                <div className='sis-docs-doc-wrapper__div'>
                    <h2 className='sis-docs-doc-wrapper__titulo'>Data programada</h2>
                    <p className='sis-docs-doc-wrapper__descricao'>
                        {this.props.util.formatarData(this.props.documento.dataProgramada)}
                    </p>
                </div>
                <div className='sis-docs-doc-wrapper__div'>
                    <h2 className='sis-docs-doc-wrapper__titulo'>Forma de pagamento</h2>
                    <p className='sis-docs-doc-wrapper__descricao'>
                        {this.props.documento.formaPagamentoNome}
                    </p>
                </div>
            </div>

            <table className='sis-docs-doc-tabela'>
                <thead>
                    <tr className='sis-docs-doc-tabela__thead'>
                        <th className='sis-docs-doc-tabela__th'>id</th>
                        <th className='sis-docs-doc-tabela__th'>Status</th>
                        <th className='sis-docs-doc-tabela__th'>Nome</th>
                        <th className='sis-docs-doc-tabela__th'>Quantidade</th>
                        <th className='sis-docs-doc-tabela__th'>Preço Unitário</th>
                        <th className='sis-docs-doc-tabela__th'>Valor total</th>
                        <th className='sis-docs-doc-tabela__th'>Valor técnico</th>
                        <th className='sis-docs-doc-tabela__th'>Margem</th>
                        <th className='sis-docs-doc-tabela__th'>Data programada</th>
                        <th className='sis-docs-doc-tabela__th'>Ação</th>
                    </tr>
                </thead>

                <tbody>
                    {this.props.listaDocumentoItem.map(pDocumentoItem =>
                        <>
                            <tr>
                                <td className='sis-docs-div2__td'>{pDocumentoItem.seqDocumentoItem}</td>
                                <td className='sis-docs-div2__td'>
                                    {pDocumentoItem.status === this.props.constante.statusServicoNaoPago ?
                                        'Não pago' : 'Pago'
                                    }
                                </td>
                                <td className='sis-docs-div2__td'>{pDocumentoItem.itemNomeVitrine}</td>
                                <td className='sis-docs-div2__td'>{pDocumentoItem.quantidade}</td>
                                <td className='sis-docs-div2__td'>{this.props.util.formatarValor(pDocumentoItem.precoUnitario)}</td>
                                <td className='sis-docs-div2__td'>{this.props.util.formatarValor(pDocumentoItem.valorTotal)}</td>
                                <td className='sis-docs-div2__td'>{this.props.util.formatarValor(pDocumentoItem.precoTabelaMinimo * pDocumentoItem.quantidade)}</td>
                                <td className='sis-docs-div2__td'>{this.props.util.formatarValor(pDocumentoItem.valorTotal - (pDocumentoItem.precoTabelaMinimo * pDocumentoItem.quantidade))}</td>
                                <td className='sis-docs-div2__td'>{this.props.util.formatarData(pDocumentoItem.dataProgramada)}</td>
                                <td className='sis-docs-div2__td'>
                                    <button
                                        className='sis-docs-doc-botao-detalhe'
                                        onClick={() => this.props.selecionarDocumentoItem(pDocumentoItem)}>
                                        <HiEye />
                                    </button>
                                </td>
                            </tr>
                        </>
                    )}
                </tbody>
            </table>
        </div>
    }
}