import { Component } from 'react';
import { Navigate } from 'react-router-dom';
import logo from '../../../../assets/logo-icone.png';
import Util from '../../../../util/Util';
import './style.css';
import { MdLogout } from 'react-icons/md';
import Constante from '../../../../util/Constante';

export default class Header extends Component {
    util = new Util();
    constante = new Constante();

    state = {
        liberarNavegacaoDigitacaoRapida: false,
        liberarNavegacaoDocumento: false,
        liberarNavegacaoPessoa: false,
        liberarNavegacaoHotsite: false,
        liberarNavegacaoFinanceiro: false,
        liberarNavegacaoExecucao: false,
        liberarNavegacaoMapaDocumento: false,
        liberarNavegacaoCadastro: false,
        liberarNavegacaoIexcombo: false,
    }

    liberarNavegacao(pTela) {
        console.log(pTela)
        switch (pTela) {
            case this.constante.Telas.Hotsite:
                if (this.props.selecionado === this.constante.Telas.Hotsite) return;
                this.setState({ liberarNavegacaoHotsite: true });
                break;

            case this.constante.Telas.DigitacaoRapida:
                if (this.props.selecionado === this.constante.Telas.DigitacaoRapida) return;
                this.setState({ liberarNavegacaoDigitacaoRapida: true });
                break;

            case this.constante.Telas.Documento:
                if (this.props.selecionado === this.constante.Telas.Documento) return;
                this.setState({ liberarNavegacaoDocumento: true });
                break;

            // case this.constante.Telas.Pessoa:
            //     if (this.props.selecionado === this.constante.Telas.Pessoa) return;
            //     this.setState({ liberarNavegacaoPessoa: true });
            //     break;

            case this.constante.Telas.Financeiro:
                if (this.props.selecionado === this.constante.Telas.Financeiro) return;
                this.setState({ liberarNavegacaoFinanceiro: true });
                break;

            case this.constante.Telas.Execucao:
                if (this.props.selecionado === this.constante.Telas.Execucao) return;
                this.setState({ liberarNavegacaoExecucao: true });
                break;

            case this.constante.Telas.MapaDocumento:
                if (this.props.selecionado === this.constante.Telas.MapaDocumento) return;
                this.setState({ liberarNavegacaoMapaDocumento: true });
                break;

                case this.constante.Telas.Cadastro:
                    if (this.props.selecionado === this.constante.Telas.Cadastro) return;
                    this.setState({ liberarNavegacaoCadastro: true });
                    break;

                    case this.constante.Telas.Iexcombo:
                        if (this.props.selecionado === this.constante.Telas.Iexcombo) return;
                        this.setState({ liberarNavegacaoIexcombo: true });
                        break;

            default:
                console.log('Algo de errado no liberarNavegacao()');
                break;
        }
    }

    sair() {
        this.util.removerLocalStorage("PESSOA_USUARIO");
        this.liberarNavegacao(this.constante.Telas.Hotsite);
    }

    render() {
        return <header className='sis-header'>
            {this.state.liberarNavegacaoHotsite && <Navigate to="/" />}
            {this.state.liberarNavegacaoDocumento && <Navigate to="/documento" />}
            {this.state.liberarNavegacaoDigitacaoRapida && <Navigate to="/digitacao" />}
            {/* {this.state.liberarNavegacaoPessoa && <Navigate to="/pessoa" />} */}
            {this.state.liberarNavegacaoFinanceiro && <Navigate to="/financeiro" />}
            {this.state.liberarNavegacaoExecucao && <Navigate to="/execucao" />}
            {this.state.liberarNavegacaoMapaDocumento && <Navigate to="/mapa/documento" />}
            {this.state.liberarNavegacaoCadastro && <Navigate to="/cadastro" />}
            {this.state.liberarNavegacaoIexcombo && <Navigate to="/iexcombo" />}

            <div className='sis-header__wrapper1'>
                <img src={logo} alt="IEX" className='sis-header__logo' />
            </div>

            <nav className='sis-header__nav'>

                {this.props.pessoaUsuario?.tags0 === 'S' && <button
                    onClick={() => this.liberarNavegacao(this.constante.Telas.Documento)}
                    className={this.props.selecionado === this.constante.Telas.Documento ?
                        'sis-header__botao-nav sis-header__selecionado' :
                        'sis-header__botao-nav'}
                >Ordens de serviço</button>}

                {this.props.pessoaUsuario?.tags1 === 'S' && <button
                    onClick={() => this.liberarNavegacao(this.constante.Telas.DigitacaoRapida)}
                    className={this.props.selecionado === this.constante.Telas.DigitacaoRapida ?
                        'sis-header__botao-nav sis-header__selecionado' :
                        'sis-header__botao-nav'}
                >Digitação rápida</button>}
                {/* <button
                    onClick={() => this.liberarNavegacao(this.constante.Telas.Pessoa)}
                    className={this.props.selecionado === this.constante.Telas.Pessoa ?
                        'sis-header__botao-nav sis-header__selecionado' :
                        'sis-header__botao-nav'}
                >Pessoa</button> */}
                {this.props.pessoaUsuario?.tags2 === 'S' && <button
                    onClick={() => this.liberarNavegacao(this.constante.Telas.Financeiro)}
                    className={this.props.selecionado === this.constante.Telas.Financeiro ?
                        'sis-header__botao-nav sis-header__selecionado' :
                        'sis-header__botao-nav'}
                >Financeiro</button>}

                <button
                    onClick={() => this.liberarNavegacao(this.constante.Telas.Execucao)}
                    className={this.props.selecionado === this.constante.Telas.Execucao ?
                        'sis-header__botao-nav sis-header__selecionado' :
                        'sis-header__botao-nav'}
                >Execução</button>

                {this.props.pessoaUsuario?.tags3 === 'S' && <button
                    onClick={() => this.liberarNavegacao(this.constante.Telas.MapaDocumento)}
                    className={this.props.selecionado === this.constante.Telas.MapaDocumento ?
                        'sis-header__botao-nav sis-header__selecionado' :
                        'sis-header__botao-nav'}
                >Mapa Documento</button>}

{this.props.pessoaUsuario?.tags4 === 'S' && <button
                    onClick={() => this.liberarNavegacao(this.constante.Telas.Cadastro)}
                    className={this.props.selecionado === this.constante.Telas.Cadastro ?
                        'sis-header__botao-nav sis-header__selecionado' :
                        'sis-header__botao-nav'}
                >Cadastro</button>}
                
                {this.props.pessoaUsuario?.tags5 === 'S' && <button
                onClick={() => this.liberarNavegacao(this.constante.Telas.Iexcombo)}
                className={this.props.selecionado === this.constante.Telas.Iexcombo ?
                    'sis-header__botao-nav sis-header__selecionado' :
                    'sis-header__botao-nav'}
            >Iexcombo</button>}
            </nav>

            <div className='sis-header__wrapper2'>
                <button
                    onClick={() => this.sair()}
                    className='sis-header__botao'
                >
                    <MdLogout />
                </button>
            </div>
        </header>
    }
}