import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa"
import PessoaRelacionamento from "lirida-back-service/Servicos/PessoaRelacionamento/PessoaRelacionamento";
import { Component } from "react"
import { FaArrowLeft } from "react-icons/fa"
import { HiEye, HiTrash } from "react-icons/hi";
import './style.css';

export default class PessoaIexcombo extends Component {
    render() {
        return <main className="sis-iex-pes-main">
            <div className="sis-iex-pes-header">
                <button
                    className='sis-iex-pes-header__botao'
                    onClick={() => this.props.setState({ pessoa: new Pessoa() })}
                >
                    <FaArrowLeft />
                </button>

                <h2 className="sis-iex-pes-header__titulo">Pessoa</h2>
            </div>
            <br />
            <div className="sis-iex-pes-wrapper">
                <div className="sis-iex-pes-wrapper__div">
                    <h3 className="sis-iex-pes-wrapper__titulo">Nome</h3>
                    <p className="sis-iex-pes-wrapper__descricao">{this.props.pessoa.nome}</p>
                </div>
                <div className="sis-iex-pes-wrapper__div">
                    <h3 className="sis-iex-pes-wrapper__titulo">Email</h3>
                    <p className="sis-iex-pes-wrapper__descricao">{this.props.pessoa.emailPlataformaLirida || ''}</p>
                </div>
                <div className="sis-iex-pes-wrapper__div">
                    <h3 className="sis-iex-pes-wrapper__titulo">Documento</h3>
                    <p className="sis-iex-pes-wrapper__descricao">{this.props.pessoa.documento}</p>
                </div>
                <div className="sis-iex-pes-wrapper__div">
                    <h3 className="sis-iex-pes-wrapper__titulo">Data nascimento</h3>
                    <p className="sis-iex-pes-wrapper__descricao">{this.props.util.formatarData(this.props.pessoa.dataNascimento)}</p>
                </div>
                <div className="sis-iex-pes-wrapper__div">
                    <h3 className="sis-iex-pes-wrapper__titulo">Endereço</h3>
                    <p className="sis-iex-pes-wrapper__descricao">{
                        this.props.enderecoPessoa.seqEndereco &&
                        `${this.props.enderecoPessoa.logradouro}, ${this.props.enderecoPessoa.numero}, ${this.props.enderecoPessoa.complemento}, ${this.props.enderecoPessoa.bairro}, ${this.props.enderecoPessoa.cidade}`
                    }</p>
                </div>
            </div>
            <div style={{ width: '100%', height: 1, backgroundColor: 'gray', margin: '1rem 0' }}></div>
            <div style={{ margin: '1rem 0' }}>
                <h2 className="sis-iex-pes-header__titulo">Pessoas Relacionadas</h2>
            </div>
            <div className="sis-iex-pes-wrapper">
                <div className="sis-iex-pes-wrapper__div">
                    <label className="sis-iex-pes-wrapper__titulo">Nome</label><br />
                    <input onChange={pTexto => this.props.setState({
                        pessoaRelacionada: {
                            ...this.props.pessoaRelacionada,
                            nome: pTexto.target.value
                        }
                    })}
                        value={this.props.pessoaRelacionada.nome || ''}
                    />
                </div>
                <div className="sis-iex-pes-wrapper__div">
                    <label className="sis-iex-pes-wrapper__titulo">CPF</label><br />
                    <input onChange={pTexto => this.props.setState({
                        pessoaRelacionada: {
                            ...this.props.pessoaRelacionada,
                            documento: this.props.util.formatarCPFCNPJ(pTexto.target.value)
                        }
                    })}
                        value={this.props.pessoaRelacionada.documento || ''}
                    />
                </div>
                <div className="sis-iex-pes-wrapper__div">
                    <label className="sis-iex-pes-wrapper__titulo">Data nascimento</label><br />
                    <input
                        type="date"
                        onChange={pTexto => this.props.setState({
                            pessoaRelacionada: {
                                ...this.props.pessoaRelacionada,
                                dataNascimento: pTexto.target.value
                            }
                        })}
                        value={this.props.pessoaRelacionada.dataNascimento || ''}
                    />
                </div><div className="sis-iex-pes-wrapper__div">
                    <label className="sis-iex-pes-wrapper__titulo">Sexo</label><br />
                    <select
                        type="date"
                        onChange={pTexto => this.props.setState({
                            pessoaRelacionada: {
                                ...this.props.pessoaRelacionada,
                                sexo: pTexto.target.value
                            }
                        })}
                        value={this.props.pessoaRelacionada.sexo || ''}
                    >
                        <option value={'M'}>Masculino</option>
                        <option value={'F'}>Feminino</option>
                    </select>
                </div>
                <button
                    className="sis-iex-pes-wrapper__botao"
                    onClick={() => this.props.salvarRelacionamento()}>salvar</button>
                {this.props.pessoaRelacionada.seqPessoa && <button
                    className="sis-iex-pes-wrapper__botao"
                    onClick={() => this.props.setState({
                        pessoaRelacionada: new Pessoa(),
                    })}>cancelar</button>}
                {/* <button 
                className="sis-iex-pes-wrapper__botao"
                onClick={() => this.props.gerarFinanceiro()}>Gerar financeiro</button>
                {this.props.pessoaRelacionada.seqPessoa && <button onClick={() => this.props.setState({ pessoaRelacionada: new Pessoa() })}>cancelar</button>} */}
            </div>

            <table className="sis-iex-pes-main__tabela">
                <thead className="sis-iex-pes-main__thead">
                    <tr>
                        <th className="sis-iex-pes-main__th">id</th>
                        <th className="sis-iex-pes-main__th">Nome</th>
                        <th className="sis-iex-pes-main__th">Documento</th>
                        <th className="sis-iex-pes-main__th">Data nascimento</th>
                        <th className="sis-iex-pes-main__th">Sexo</th>
                        <th className="sis-iex-pes-main__th">Ação</th>
                    </tr>
                </thead>

                <tbody>
                    {this.props.listaPessoaRelacionada.map(pPessoa =>
                        pPessoa.seqTipoRelacionamento === this.props.constante.seqTipoRelacionamentoDependenteIexcombo ?
                            <tr>
                                <td className="sis-iex-pes-main__td">{pPessoa.seqPessoa}</td>
                                <td className="sis-iex-pes-main__td">{pPessoa.pessoaRelacionadaNome}</td>
                                <td className="sis-iex-pes-main__td">{this.props.util.formatarCPFCNPJ(pPessoa.pessoaRelacionadaDocumento)}</td>
                                <td className="sis-iex-pes-main__td">{this.props.util.formatarData(pPessoa.pessoaRelacionadaDataNascimento)}</td>
                                <td className="sis-iex-pes-main__td">{pPessoa.pessoaRelacionadaSexo}</td>
                                <td className="sis-iex-pes-main__td">
                                    <button
                                        className="sis-iex-pes-botao-detalhe"
                                        onClick={() => this.props.selecionarPessoaRelacionamento(pPessoa)}>
                                        <HiEye />
                                    </button>
                                    <button
                                        className="sis-iex-pes-botao-detalhe"
                                        onClick={() => this.props.deletarRelacionamento(pPessoa)}>
                                        <HiTrash />
                                    </button>
                                </td>
                            </tr>
                            : null
                    )}
                </tbody>
            </table>

            <button
                className="sis-iex-pes-wrapper__botao"
                onClick={() => this.props.enviarAIG()}>Enviar para AIG</button>

            {this.props.pessoa.tags8 ? this.props.pessoa.tags8 : null}
        </main>
    }
}