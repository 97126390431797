import DocumentoService from 'lirida-back-service/Servicos/Documento/DocumentoService';
import { Component } from 'react';
import Constante from '../../../../util/Constante';
import Util from '../../../../util/Util';
import Header from '../Header';
import './style.css';

export default class Execucao extends Component {
    constante = new Constante();
    util = new Util(this.constante.token, this.constante.url_base);
    documentoService = new DocumentoService(this.constante.token, this.constante.url_base);

    async listarDocumentoComFiltro() {
        this.listaPesquisa = [
            this.util.retornaPesquisa(
                'documento.seq_usuario',
                this.constante.seqUsuario,
                '',
                'NUMERO'
            ),
            this.util.retornaPesquisa(
                'documento.seq_tipo_documento_processo',
                this.constante.seqTipoDocumentoProcessoAguardandoPagamento,
                'AND',
                'NUMERO'
            ),
            this.util.retornaPesquisa(
                'documento.pago',
                'S',
                'AND',
                'TEXTO'
            ),
        ];

        this.retornoListaDocumento = await this.documentoService.listarComFiltro(
            this.listaPesquisa
        );

        return this.retornoListaDocumento.objeto;
    }

    async listarDocumentoItemComFiltro() {
        this.listaPesquisa = [
            this.util.retornaPesquisa(
                'documento.seq_usuario',
                this.constante.seqUsuario,
                '',
                'NUMERO'
            ),
            this.util.retornaPesquisa(
                'documento.seq_tipo_documento_processo',
                this.constante.seqTipoDocumentoProcessoAguardandoPagamento,
                'AND',
                'NUMERO'
            ),
            this.util.retornaPesquisa(
                'documento_item.pago',
                'S',
                'AND',
                'TEXTO'
            ),
        ];

        this.retornoListaDocumentoItem = await this.documentoService.listarItemComFiltro(
            this.listaPesquisa
        );

        return this.retornoListaDocumentoItem.objeto;
    }

    async atualizarListaDocumento() {
        this.listaDocumento = await this.listarDocumentoComFiltro();
        this.listaDocumentoItem = await this.listarDocumentoItemComFiltro();

        console.log(this.listaDocumento);
        console.log(this.listaDocumentoItem);

        for (let i = 0; i < this.listaDocumento.length; i++) {
            this.listaDocumento[i].seqTipoDocumentoProcesso = this.constante.seqTipoDocumentoProcessoPrimeiroAgendamento;
            this.listaDocumento[i].status = 0;
            await this.documentoService.salvar(this.listaDocumento[i]);
        }

        for (let i = 0; i < this.listaDocumentoItem.length; i++) {
            this.listaDocumentoItem[i].seqTipoDocumentoProcesso = this.constante.seqTipoDocumentoProcessoPrimeiroAgendamento;
            this.listaDocumentoItem[i].status = 0;
            await this.documentoService.salvarItem(this.listaDocumentoItem[i]);
        }

        console.log('a')
    }

    render() {
        return <div className='sis-exe-container'>
            <Header selecionado="sis-exe" />

            <button
                onClick={() => this.atualizarListaDocumento()}
                className='sis-docs-div2__botao-atualizar'
            >Atualizar Documentos</button>
        </div>
    }
}