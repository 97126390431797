import { Component } from 'react';
import './style.css';

export default class DataAgendamento extends Component {
    render() {
        return <div className="sis-ch-da-container">
            <h2 className='sis-ch-da-titulo'>Agendamento</h2>

            <div className='sis-ch-da-wrapper'>
                <div className={
                    this.props.mobile ?
                        'sis-ch-da-input-wrapper--mobile' :
                        'sis-ch-da-input-wrapper'
                }>
                    <label className='sis-ch-da-label'>Melhor data para a realização do serviço</label>
                    <input
                        className='sis-ch-da-input'
                        type="date"
                        onChange={pTexto => this.props.setState({
                            documento: {
                                ...this.props.documento,
                                dataProgramada: pTexto.target.value
                            }
                        })}
                        value={this.props.documento.dataProgramada}
                    />
                </div>
            </div>
        </div>
    }
}