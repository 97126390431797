import { Component } from 'react';
import './style.css';

export default class EscolhaCategoria extends Component {
    render() {
        return <div className='sis-dr-ec-container'>
            <h2 className='sis-dr-ec-titulo'>Escolha a categoria</h2>

            <div className='sis-dr-ec-wrapper'>
                {this.props.listaCategoria.map(item =>
                    <button
                        onClick={() => this.props.setState({ categoria: item })}
                        className={this.props.categoria === item ?
                            'sis-dr-ec-wrapper__botao sis-dr-ec-wrapper__selecionado' :
                            'sis-dr-ec-wrapper__botao'}
                    >
                        <p className='sis-dr-ec-wrapper__titulo'>
                            {item.itemNomeVitrine}
                            <span className='sis-dr-ec-wrapper__preco'>
                                {this.props.formatarValor(item.precoMaximo)}
                            </span>
                        </p>
                    </button>
                )}
            </div>
        </div>
    }
}