import {Component} from 'react';
import './style.css';

export default class Menu extends Component {
    render() {
        return <div className='hs-men-container'>
            <button onClick={() => this.props.mostrarMenu()}>
                fechar
            </button>

            <button onClick={() => this.props.mostrarLogin()}>
                login
            </button>
        </div>
    }
}