import Documento from 'lirida-back-service/Servicos/Documento/Documento';
import DocumentoItem from 'lirida-back-service/Servicos/Documento/DocumentoItem';
import DocumentoService from 'lirida-back-service/Servicos/Documento/DocumentoService';
import DocumentoItemCaracteristicaDocumentoItem from 'lirida-back-service/Servicos/DocumentoItemCaracteristicaDocumentoItem/DocumentoItemCaracteristicaDocumentoItem';
import FinanceiroService from 'lirida-back-service/Servicos/Financeiro/FinanceiroService';
import { Component } from 'react';
import { HiEye } from 'react-icons/hi';
import { FaRegMoneyBillAlt } from 'react-icons/fa';
import Loading from '../../../../components/Loading';
import Constante from '../../../../util/Constante';
import Util from '../../../../util/Util';
import DocumentoItemPg from '../Documentos/components/DocumentoItemPg';
import DocumentoPg from '../Documentos/components/DocumentoPg';
import Header from '../Header';
import { FaFileExcel } from "react-icons/fa";
import './style.css';
import ReactHtmlTableToExcel from 'react-html-table-to-excel';
import Pessoa from 'lirida-back-service/Servicos/Pessoa/Pessoa';

export default class Financeiros extends Component {
    constante = new Constante();
    util = new Util(this.constante.token, this.constante.url_base);
    financeiroService = new FinanceiroService(this.constante.token, this.constante.url_base);
    documentoService = new DocumentoService(this.constante.token, this.constante.url_base);

    state = {
        urlBaseFinanceiro: '',
        pessoaUsuario: new Pessoa(),

        documento: new Documento(),
        documentoItem: new DocumentoItem(),
        documentoItemCaracteristicaDocumentoItem: new DocumentoItemCaracteristicaDocumentoItem(),
        listaFinanceiro: [],
        listaContaBancaria: [],
        listaContaFinanceira: [],
        listaFormaPagamento: [],
        listaDocumentoItem: [],
        listaDocumentoItemRelacionamento: [],
        listaCarregando: false,

        /* Filtros */
        seqContaBancaria: '',
        seqFormaPagamento: '',
        seqContaFinanceira: '',
        status: '',
        dataCadastro_inicial: '',
        dataCadastro_final: '',
        dataProgramada_inicial: '',
        dataProgramada_final: '',
        dataBaixa_inicial: '',
        dataBaixa_final: '',
        dataCredito_inicial: '',
        dataCredito_final: '',
    }

    componentDidMount() {
        this.iniciar();
    }

    async iniciar() {
        this.setState({ listaCarregando: true });
        this.urlBaseFinanceiro = await this.util.buscarUrlBaseFinanceiro();
        console.log('financeiro')
        console.log(this.urlBaseFinanceiro);
        this.pessoaUsuario = await this.util.buscarLocalStorage('PESSOA_USUARIO');
        this.util.validarAcessoTela(this.constante.Telas.Financeiro, this.pessoaUsuario);
        console.log(this.pessoaUsuario);
        this.retornoListaContaFinanceira = await this.util.listarLista(this.constante.seqUsuario, this.constante.idListaContaFinanceira);
        this.retornoListaFormaPagamento = await this.util.listarLista(this.constante.seqUsuario, this.constante.idListaFormaPagamento);
        this.retornoListaContaBancaria = await this.util.listarLista(this.constante.seqUsuario, this.constante.idListaContaBancaria);

        console.log(this.retornoListaContaFinanceira);
        console.log(this.retornoListaContaPagamento);
        console.log(this.retornoListaContaBancaria);

        this.listaContaFinanceira = this.retornoListaContaFinanceira.objeto;
        this.listaFormaPagamento = this.retornoListaFormaPagamento.objeto;
        this.listaContaBancaria = this.retornoListaContaBancaria.objeto;

        await this.listar(this.pessoaUsuario);

        this.setState({
            listaContaFinanceira: this.listaContaFinanceira,
            listaFormaPagamento: this.listaFormaPagamento,
            listaContaBancaria: this.listaContaBancaria,
            urlBaseFinanceiro: this.urlBaseFinanceiro,
            pessoaUsuario: this.pessoaUsuario,
        });
    }

    async listar(pPessoa) {
        if (pPessoa) {
            this.pessoa = pPessoa;
        } else {
            this.pessoa = this.state.pessoa;
        }

        this.listaPesquisa = [];
        this.listaPesquisa.push(
            this.util.retornaPesquisa(
                "movimento_financeiro.seq_usuario",
                this.constante.seqUsuario,
                "",
                "NUMERO"
            )
        );

        if (this.state.seqContaBancaria) {
            this.listaPesquisa.push(
                this.util.retornaPesquisa(
                    "movimento_financeiro.seq_conta_bancaria",
                    this.state.seqContaBancaria,
                    "AND",
                    "NUMERO"
                )
            );
        }

        if (this.state.seqFormaPagamento) {
            this.listaPesquisa.push(
                this.util.retornaPesquisa(
                    "movimento_financeiro.seq_forma_pagamento",
                    this.state.seqFormaPagamento,
                    "AND",
                    "NUMERO"
                )
            );
        }

        if (this.state.seqContaFinanceira) {
            this.listaPesquisa.push(
                this.util.retornaPesquisa(
                    "movimento_financeiro.seq_conta_financeira",
                    this.state.seqContaFinanceira,
                    "AND",
                    "NUMERO"
                )
            );
        }

        if (this.state.status) {
            this.listaPesquisa.push(
                this.util.retornaPesquisa(
                    "movimento_financeiro.qtde_baixa",
                    this.state.status,
                    "AND",
                    "NUMERO"
                )
            );
        }

        if (this.state.dataCadastro_inicial) {
            this.listaPesquisa.push(
                this.util.retornaPesquisa(
                    "movimento_financeiro.data_cadastro",
                    this.util.formatarDataInput(this.state.dataCadastro_inicial),
                    "AND",
                    "DATA_INICIAL"
                )
            );
        }

        if (this.state.dataCadastro_final) {
            this.listaPesquisa.push(
                this.util.retornaPesquisa(
                    "movimento_financeiro.data_cadastro",
                    this.util.formatarDataInput(this.state.dataCadastro_final),
                    "AND",
                    "DATA_FINAL"
                )
            );
        }

        if (this.state.dataProgramada_inicial) {
            this.listaPesquisa.push(
                this.util.retornaPesquisa(
                    "movimento_financeiro.data_programado",
                    this.util.formatarDataInput(this.state.dataProgramada_inicial),
                    "AND",
                    "DATA_INICIAL"
                )
            );
        }

        if (this.state.dataProgramada_final) {
            this.listaPesquisa.push(
                this.util.retornaPesquisa(
                    "movimento_financeiro.data_programado",
                    this.util.formatarDataInput(this.state.dataProgramada_final),
                    "AND",
                    "DATA_FINAL"
                )
            );
        }

        if (this.state.dataBaixa_inicial) {
            this.listaPesquisa.push(
                this.util.retornaPesquisa(
                    "movimento_financeiro.data_baixa",
                    this.util.formatarDataInput(this.state.dataBaixa_inicial),
                    "AND",
                    "DATA_INICIAL"
                )
            );
        }

        if (this.state.dataBaixa_final) {
            this.listaPesquisa.push(
                this.util.retornaPesquisa(
                    "movimento_financeiro.data_baixa",
                    this.util.formatarDataInput(this.state.dataBaixa_final),
                    "AND",
                    "DATA_FINAL"
                )
            );
        }

        if (this.state.dataCredito_inicial) {
            this.listaPesquisa.push(
                this.util.retornaPesquisa(
                    "movimento_financeiro.data_credito",
                    this.util.formatarDataInput(this.state.dataCredito_inicial),
                    "AND",
                    "DATA_INICIAL"
                )
            );
        }

        if (this.state.dataCredito_final) {
            this.listaPesquisa.push(
                this.util.retornaPesquisa(
                    "movimento_financeiro.data_credito",
                    this.util.formatarDataInput(this.state.dataCredito_final),
                    "AND",
                    "DATA_FINAL"
                )
            );
        }

        this.retornoListaFinanceiro = await this.financeiroService.listarComFiltro(
            this.listaPesquisa
        );

        console.log(this.retornoListaFinanceiro);

        this.setState({
            listaFinanceiro: this.retornoListaFinanceiro.objeto,
            listaCarregando: false,
        });
    }

    mostrarObservacao(pFinanceiro) {
        alert(pFinanceiro.observacao);
    }

    /* DOCUMENTO */

    async selecionar(pFinanceiro) {
        this.retornoDocumento = await this.documentoService.buscar(
            this.constante.seqUsuario, pFinanceiro.seqDocumento
        );

        this.documento = this.retornoDocumento.objeto;

        this.setState({ documento: this.documento });

        await this.listarDocumentoItem(this.documento);
        await this.listarTipoDocumentoProcessoItem(this.documento.seqTipoDocumentoProcesso);
        await this.listarDocumentoRelacionamento(this.documento);
        await this.listarCaracteristicaDocumento(this.documento);
    }

    async listarDocumentoRelacionamento(pDocumento) {
        let documento;

        if (pDocumento) {
            documento = pDocumento;
        } else {
            documento = this.state.documento;
        }

        let retornoListaDocumentoRelacionamento = await this.documentoService.listarRelacionamento(
            documento
        );
        this.setState({
            listaDocumentoRelacionamento: retornoListaDocumentoRelacionamento.objeto
        })
    }

    async listarCaracteristicaDocumento(pDocumento) {
        let documento;

        if (pDocumento) {
            documento = pDocumento;
        } else {
            documento = this.state.documento;
        }

        let retorno = await this.documentoCaracteristicaDocumentoService.listar(documento);
        this.setState({ listaDocumentoCaracteristica: retorno.objeto })
    }

    /* DOCUMENTO ITEM */

    async selecionarDocumentoItem(pDocumentoItem) {
        await this.listarRelacionamentoItem(pDocumentoItem);
        await this.listarCaracteristicaDocumentoItem(pDocumentoItem);
    }

    async listarDocumentoItem(pDocumento) {
        let documento;

        if (pDocumento) {
            documento = pDocumento;
        } else {
            documento = this.state.documento;
        }

        let retornoDocumentoItem = await this.documentoService.listarDocumentoItem(
            documento
        );
        this.setState({ listaDocumentoItem: retornoDocumentoItem.objeto });
    }

    async listarTipoDocumentoProcessoItem(pSeqTipoDocumentoProcesso) {
        let retorno = await this.tipoDocumentoProcessoService.listarTipoDocumentoProcessoItem(
            pSeqTipoDocumentoProcesso
        );
        this.setState({ listaTipoDocumentoProcessoItem: retorno.objeto });
    }


    async listarRelacionamentoItem(pDocumentoItem) {
        this.retornoDocumentoItemRelacionamento = await this.documentoService.listarRelacionamentoItem(pDocumentoItem);
        this.setState({
            documentoItem: pDocumentoItem,
            listaDocumentoItemRelacionamento: this.retornoDocumentoItemRelacionamento.objeto,
        })
    }

    async listarCaracteristicaDocumentoItem(pDocumentoItem) {
        this.documentoItem = new DocumentoItem();
        this.documentoItemCaracteristicaDocumentoItem = new DocumentoItemCaracteristicaDocumentoItem();

        if (pDocumentoItem) {
            this.documentoItem = pDocumentoItem;
        } else {
            this.documentoItem = this.state.documentoItem;
        }

        this.retorno = await this.documentoItemCaracteristicaDocumentoItemService.listar(this.documentoItem);

        this.listaDocumentoItemCaracteristicaDocumentoItem = this.retorno.objeto;

        for (let i = 0; i < this.listaDocumentoItemCaracteristicaDocumentoItem.length; i++) {
            if (this.listaDocumentoItemCaracteristicaDocumentoItem[i].marcado === "true") {
                this.documentoItemCaracteristicaDocumentoItem = this.listaDocumentoItemCaracteristicaDocumentoItem[i];
            }
        }

        this.setState({
            listaDocumentoItemCaracteristicaDocumentoItem: this.listaDocumentoItemCaracteristicaDocumentoItem,
            documentoItemCaracteristicaDocumentoItem: this.documentoItemCaracteristicaDocumentoItem,
        });
    }

    retornaTotalLiquido() {
        this.listaFinanceiro = this.state.listaFinanceiro;

        this.valorTotal = 0;

        for (let i = 0; i < this.listaFinanceiro.length; i++) {
            this.valorTotal = this.valorTotal + this.listaFinanceiro[i].valorLiquido;
        }

        return Number(this.valorTotal);
    }

    retornaTotal() {
        this.listaFinanceiro = this.state.listaFinanceiro;

        this.valorTotal = 0;

        for (let i = 0; i < this.listaFinanceiro.length; i++) {
            this.valorTotal = this.valorTotal + this.listaFinanceiro[i].valorProgramado;
        }

        return Number(this.valorTotal);
    }

    render() {
        return this.state.pessoaUsuario.seqPessoa && <div className='sis-fi-container'>

            {this.state.documentoItem.seqDocumentoItem &&
                <DocumentoItemPg
                    util={this.util}
                    constante={this.constante}
                    documentoItemCaracteristicaDocumentoItem={this.state.documentoItemCaracteristicaDocumentoItem}
                    listaDocumentoItemRelacionamento={this.state.listaDocumentoItemRelacionamento}
                    documentoItem={this.state.documentoItem}
                    setState={pState => this.setState(pState)}
                />
            }

            <Header
                pessoaUsuario={this.state.pessoaUsuario}
                selecionado={this.constante.Telas.Financeiro} />

            {this.state.documento.seqDocumento &&
                <DocumentoPg
                    util={this.util}
                    constante={this.constante}
                    selecionarDocumentoItem={pDocumentoItem => this.selecionarDocumentoItem(pDocumentoItem)}
                    listaDocumentoItem={this.state.listaDocumentoItem}
                    documento={this.state.documento}
                    setState={pState => this.setState(pState)}
                />
            }

            {!this.state.documento.seqDocumento &&
                <main className='sis-fi-main'>
                    <div className='sis-fi-main__container'>
                        <h2 className='sis-fi-main__titulo'>
                            Financeiro
                        </h2>

                        <div className='sis-fi-main__wrapper'>
                            <div>
                                <div>
                                    <label>Conta Bancária</label>
                                </div>
                                <select
                                    onChange={pTexto => this.setState({ seqContaBancaria: pTexto.target.value })}
                                    value={this.state.seqContaBancaria}
                                    className='sis-fi-main__select'
                                >
                                    <option value={''}>Selecione</option>
                                    {this.state.listaContaBancaria.map(pContaBancaria =>
                                        <option value={pContaBancaria.seq}>{pContaBancaria.nome}</option>
                                    )}
                                </select>
                            </div>
                            <div>
                                <div>
                                    <label>Forma de pagamento</label>
                                </div>
                                <select
                                    onChange={pTexto => this.setState({ seqFormaPagamento: pTexto.target.value })}
                                    value={this.state.seqFormaPagamento}
                                    className='sis-fi-main__select'
                                >
                                    <option value={''}>Selecione</option>
                                    {this.state.listaFormaPagamento.map(pFormaPagamento =>
                                        <option value={pFormaPagamento.seq}>{pFormaPagamento.nome}</option>
                                    )}
                                </select>
                            </div>
                            <div>
                                <div>
                                    <label>Conta Financeira</label>
                                </div>
                                <select
                                    onChange={pTexto => this.setState({ seqContaFinanceira: pTexto.target.value })}
                                    value={this.state.seqContaFinanceira}
                                    className='sis-fi-main__select'
                                >
                                    <option value={''}>Selecione</option>
                                    {this.state.listaContaFinanceira.map(pContaFinanceira =>
                                        <option value={pContaFinanceira.seq}>{pContaFinanceira.nome}</option>
                                    )}
                                </select>
                            </div>
                            <div>
                                <div>
                                    <label>Status</label>
                                </div>
                                <select
                                    onChange={pTexto => this.setState({ status: pTexto.target.value })}
                                    value={this.state.status}
                                    className='sis-fi-main__select'
                                >
                                    <option value={''}>Selecione</option>
                                    <option value={'0'}>Não pago</option>
                                    <option value={'1'}>Pago</option>
                                </select>
                            </div>
                            <div>
                                <div>
                                    <label>Data cadastro</label>
                                </div>
                                <div className='sis-fi-main__input-wrapper'>
                                    <input
                                        onChange={pTexto => this.setState({ dataCadastro_inicial: pTexto.target.value })}
                                        value={this.state.dataCadastro_inicial}
                                        className="sis-fi-main__input"
                                        type="date"
                                    />
                                    <input
                                        onChange={pTexto => this.setState({ dataCadastro_final: pTexto.target.value })}
                                        value={this.state.dataCadastro_final}
                                        className="sis-fi-main__input"
                                        type="date"
                                    />
                                </div>
                            </div>
                            <div>
                                <div>
                                    <label>Data programada</label>
                                </div>
                                <div className='sis-fi-main__input-wrapper'>
                                    <input
                                        onChange={pTexto => this.setState({ dataProgramada_inicial: pTexto.target.value })}
                                        value={this.state.dataProgramada_inicial}
                                        className="sis-fi-main__input"
                                        type="date"
                                    />
                                    <input
                                        onChange={pTexto => this.setState({ dataProgramada_final: pTexto.target.value })}
                                        value={this.state.dataProgramada_final}
                                        className="sis-fi-main__input"
                                        type="date"
                                    />
                                </div>
                            </div>
                            <div>
                                <div>
                                    <label>Data baixa</label>
                                </div>
                                <div className='sis-fi-main__input-wrapper'>
                                    <input
                                        onChange={pTexto => this.setState({ dataBaixa_inicial: pTexto.target.value })}
                                        value={this.state.dataBaixa_inicial}
                                        className="sis-fi-main__input"
                                        type="date"
                                    />
                                    <input
                                        onChange={pTexto => this.setState({ dataBaixa_final: pTexto.target.value })}
                                        value={this.state.dataBaixa_final}
                                        className="sis-fi-main__input"
                                        type="date"
                                    />
                                </div>
                            </div>
                            <div>
                                <div>
                                    <label>Data crédito</label>
                                </div>
                                <div className='sis-fi-main__input-wrapper'>
                                    <input
                                        onChange={pTexto => this.setState({ dataCredito_inicial: pTexto.target.value })}
                                        value={this.state.dataCredito_inicial}
                                        className="sis-fi-main__input"
                                        type="date"
                                    />
                                    <input
                                        onChange={pTexto => this.setState({ dataCredito_final: pTexto.target.value })}
                                        value={this.state.dataCredito_final}
                                        className="sis-fi-main__input"
                                        type="date"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className='sis-fi-main__wrapper'>
                            <button
                                onClick={() => this.listar()}
                                className='sis-fi-main__botao-busca'
                            >
                                Buscar
                            </button>
                        </div>

                        <div className='sis-fi-main__resumo'>
                            <div>
                                <h4>Total Líquido</h4>
                                <p>{this.util.formatarValor(this.retornaTotalLiquido())}</p>
                            </div>
                            <div>
                                <h4>Total</h4>
                                <p>{this.util.formatarValor(this.retornaTotal())}</p>
                            </div>
                            <div>
                                <h4>Quantidade</h4>
                                <p>{this.state.listaFinanceiro.length}</p>
                            </div>

                            <ReactHtmlTableToExcel
                                className="sis-fi-main__botao-excel"
                                table="tabela-financeiro"
                                filename="Financeiro"
                                sheet="Pagina 1"
                                buttonText={<FaFileExcel />}
                            />
                        </div>

                        <table className='sis-fi-main__tabela' id="tabela-financeiro">
                            <thead>
                                <tr className='sis-fi-main__thead'>
                                    <th className='sis-fi-main__th'>id</th>
                                    <th className='sis-fi-main__th'>Nome</th>
                                    <th className='sis-fi-main__th'>Forma pagamento</th>
                                    <th className='sis-fi-main__th'>Operação</th>
                                    <th className='sis-fi-main__th'>Valor</th>
                                    <th className='sis-fi-main__th'>Valor Taxa</th>
                                    <th className='sis-fi-main__th'>Valor Líquido</th>
                                    <th className='sis-fi-main__th'>Status</th>
                                    <th className='sis-fi-main__th'>Data programada</th>
                                    <th className='sis-fi-main__th'>Data crédito</th>
                                    <th className='sis-fi-main__th'>Data cadastro</th>
                                    <th className='sis-fi-main__th'>Ação</th>
                                </tr>
                            </thead>

                            <tbody>
                                {this.state.listaFinanceiro.map(pFinanceiro =>
                                    <tr>
                                        <td className='sis-fi-main__td'>{pFinanceiro.seqMovimentoFinanceiro}</td>
                                        <td className='sis-fi-main__td'>{pFinanceiro.pesNome}</td>
                                        <td className='sis-fi-main__td'>{pFinanceiro.fpNome}</td>
                                        <td className='sis-fi-main__td'>{pFinanceiro.cfOperacao}</td>
                                        <td className='sis-fi-main__td'>{this.util.formatarValor(pFinanceiro.valorProgramado)}</td>
                                        <td className='sis-fi-main__td'>{this.util.formatarValor(pFinanceiro.valorTaxa)}</td>
                                        <td className='sis-fi-main__td'>{this.util.formatarValor(pFinanceiro.valorLiquido)}</td>
                                        <td className='sis-fi-main__td'>
                                            {pFinanceiro.qtdeBaixa === 0 && 'Não pago'}
                                            {pFinanceiro.qtdeBaixa === 1 && 'Pago'}
                                        </td>
                                        <td className='sis-fi-main__td'>{this.util.formatarData(pFinanceiro.dataProgramado)}</td>
                                        <td className='sis-fi-main__td'>{this.util.formatarData(pFinanceiro.dataCredito)}</td>
                                        <td className='sis-fi-main__td'>{this.util.formatarData(pFinanceiro.dataCadastro)}</td>
                                        <td className='sis-fi-main__td'>
                                            <div className='sis-fi-main__tabela-botao-wrapper'>
                                                <button
                                                    className='sis-fi-main__botao'
                                                    onClick={() => this.selecionar(pFinanceiro)}>
                                                    <HiEye />
                                                </button>
                                                <button
                                                    className='sis-fi-main__botao'
                                                    onClick={() => window.open(this.state.urlBaseFinanceiro + pFinanceiro.chave, '_blank')}>
                                                    <FaRegMoneyBillAlt />
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>

                        {this.state.listaCarregando && <Loading />}
                    </div>
                </main>
            }
        </div>
    }
}