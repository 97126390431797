import DocumentoItem from 'lirida-back-service/Servicos/Documento/DocumentoItem';
import { Component } from 'react';
import { MdClose } from 'react-icons/md';
import './style.css';

export default class DocumentoItemPg extends Component {
    render() {
        return <div className='sis-docs-docit-main-container'>
            <div className='sis-docs-docit-container'>
                <div className='sis-docs-docit-header'>
                    <h2 className='sis-docs-docit-header__titulo'>Detalhe do item</h2>
                    <button
                        onClick={() => this.props.setState({ documentoItem: new DocumentoItem() })}
                        className='sis-docs-docit-header__botao'
                    >
                        <MdClose />
                    </button>
                </div>

                <button
                    className='sis-docs-docit-header__botao-financeiro'
                    onClick={() => this.props.gerarFinanceiro()}
                >
                    Gerar financeiro
                </button>

                <div className='sis-docs-docit-wrapper'>
                    <div className='sis-docs-docit-wrapper__div'>
                        <h2 className='sis-docs-docit-wrapper__titulo'>Serviço</h2>
                        <p className='sis-docs-docit-wrapper__descricao'>
                            {this.props.documentoItemCaracteristicaDocumentoItem.nome}
                        </p>
                    </div>
                    <div className='sis-docs-docit-wrapper__div'>
                        <h2 className='sis-docs-docit-wrapper__titulo'>Status</h2>
                        <p className='sis-docs-docit-wrapper__descricao'>
                            {this.props.documentoItem.status === this.props.constante.statusServicoNaoPago ?
                                'Não pago' : 'Pago'
                            }
                        </p>
                    </div>
                    <div className='sis-docs-docit-wrapper__div'>
                        <h2 className='sis-docs-docit-wrapper__titulo'>Nome</h2>
                        <p className='sis-docs-docit-wrapper__descricao'>
                            {this.props.documentoItem.itemNomeVitrine}
                        </p>
                    </div>
                    <div className='sis-docs-docit-wrapper__div'>
                        <h2 className='sis-docs-docit-wrapper__titulo'>Quantidade</h2>
                        <p className='sis-docs-docit-wrapper__descricao'>
                            {this.props.documentoItem.quantidade}
                        </p>
                    </div>
                    <div className='sis-docs-docit-wrapper__div'>
                        <h2 className='sis-docs-docit-wrapper__titulo'>Preço unitário</h2>
                        <p className='sis-docs-docit-wrapper__descricao'>
                            {this.props.util.formatarValor(this.props.documentoItem.precoTabelaMaximo)}
                        </p>
                    </div>
                    <div className='sis-docs-docit-wrapper__div'>
                        <h2 className='sis-docs-docit-wrapper__titulo'>Valor total</h2>
                        <p className='sis-docs-docit-wrapper__descricao'>
                            {this.props.util.formatarValor(this.props.documentoItem.valorTotal)}
                        </p>
                    </div>
                    <div className='sis-docs-docit-wrapper__div'>
                        <h2 className='sis-docs-docit-wrapper__titulo'>Data programada</h2>
                        <p className='sis-docs-docit-wrapper__descricao'>
                            {this.props.util.formatarData(this.props.documentoItem.dataProgramada)}
                        </p>
                    </div>
                </div>

                <table className='sis-docs-docit-tabela'>
                    <thead className='sis-docs-docit-tabela__thead'>
                        <th className='sis-docs-docit-tabela__th'>Nome</th>
                        <th className='sis-docs-docit-tabela__th'>Relacionamento</th>
                        <th className='sis-docs-docit-tabela__th'>Data cadastro</th>
                    </thead>

                    <tbody>
                        {this.props.listaDocumentoItemRelacionamento.map(pDocumentoItemRelacionamento =>
                            <tr>
                                <td className='sis-docs-docit-tabela__td'>{pDocumentoItemRelacionamento.pessoaRelacionadaNome}</td>
                                <td className='sis-docs-docit-tabela__td'>{pDocumentoItemRelacionamento.tipoRelacionamentoNome}</td>
                                <td className='sis-docs-docit-tabela__td'>{this.props.util.formatarData(pDocumentoItemRelacionamento.dataCadastro)}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    }
}